<template>
  <div class="page" style="text-align: left">
    <div class="row">
      <div style="width: 99%">
        <div class="header" id="header" style="width: 100%; height: 100px">
          <div style="width: 11%; float: left">
            <img
              src="../../assets/print_logo.jpg"
              style="width: 100px; height: 100px"
            />
          </div>
          <div style="width: 89%; float: right">
            <div
              style="
                font-size: 28px;
                font-family: Times New Roman;
                text-align: center;
                vertical-align: text-top;
              "
            >
              <b>Draco Natural Products, Inc. </b>
            </div>
            <div
              style="
                font-size: 28px;
                font-family: Times New Roman;
                text-align: center;
                vertical-align: text-top;
              "
            >
              <b><u>PRODUCT SPECIFICATION</u></b>
            </div>
          </div>
        </div>

        <!-- Product info -->
        <table style="width: 99%">
          <tr v-show="printModel.params.productName">
            <td style="width: 25%"><b>Product Name</b></td>
            <td style="width: 75%">
              <span t-field="o.name" />{{ printModel.params.productName }}
            </td>
          </tr>
          <tr v-show="printModel.params.versionNumber">
            <td style="width: 25%"><b>Version Number</b></td>
            <td style="width: 75%">
              <span t-field="o.name" />{{ printModel.params.versionNumber }}
            </td>
          </tr>

          <tr v-show="printModel.params.itemCode">
            <td style="width: 25%"><b>Item Code</b></td>
            <td style="width: 75%">
              <span t-field="o.default_code" />{{ printModel.params.itemCode }}
            </td>
          </tr>
          <tr v-show="printModel.processingArr.length > 0">
            <td style="width: 25%"><b>Processing</b></td>
            <td style="width: 75%">
              <span
                t-field="o.description"
                :key="i"
                v-for="(tag, i) in printModel.processingArr"
                >{{ tag }}
                <template v-if="i + 1 < printModel.processingArr.length"
                  >,</template
                >
              </span>
            </td>
          </tr>
        </table>
        <table style="width: 99%" v-show="printModel.BaseInfoCustomFieldAttr && printModel.BaseInfoCustomFieldAttr.length>0">
          <tr v-for="(baseinfoCustom,baseinfoIndex) in printModel.BaseInfoCustomFieldAttr" :key="baseinfoIndex">
            <td style="width: 25%"><b>{{baseinfoCustom.baseInfoInput1}}</b></td>
            <td style="width: 75%">
              {{ baseinfoCustom.baseInfoInput2 }}
            </td>
          </tr>
        </table>

        <!-- <div style="height: 10px"></div> -->
        <div style="font-size: 18px">
          <b><u>General Specification</u></b>
        </div>
        <!-- 遍历table -->
        <template
          v-if="
            printModel.botanicalNameArr.length > 0 ||
            printModel.commonNameArr.length > 0 ||
            printModel.partUsedArr.length > 0
          "
        >
          <table
            style="width: 98%;border-collapse: collapse;"
            cellspacing="0"
            class="tab"
          >
            <thead>
              <tr style="text-align: left;border-bottom:2px solid gray">
                <th v-show="printModel.commonNameArr.length > 0">
                  Common Name
                </th>
                <!-- <th v-show="typeof(printModel.inclNameArr) == undefined ? true : printModel.inclNameArr.length>0">
                                                INCI Name
                                            </th> -->
                <th v-show="printModel.botanicalNameArr.length > 0">
                  Botanical Name
                </th>
                <th v-show="printModel.partUsedArr.length > 0">Part Used</th>
              </tr>
            </thead>
            <tbody>
              <tr :key="i" v-for="(tag, i) in maxArr">
                <td v-show="i + 1 <= printModel.commonNameArr.length">
                  {{ printModel.commonNameArr[i] }}
                </td>
                <!-- <td v-show="(i+1)<=printModel.inclNameArr.length">{{printModel.inclNameArr[i]}}</td> -->
                <td v-show="i + 1 <= printModel.botanicalNameArr.length" style="font-style:italic;">
                  {{ printModel.botanicalNameArr[i] }}
                </td>
                <td v-show="i + 1 <= printModel.partUsedArr.length">
                  {{ printModel.partUsedArr[i] }}
                </td>
              </tr>
            </tbody>
          </table>
        </template>

        
        <template v-if="isShowTable">
          <table
            style="width: 98%;margin-top:10px;"
            cellspacing="0"
            class="tab"
          >
            <tbody>              
              <tr>
                <td v-show="printModel.params.tableInput1" style="width:20%;">{{ printModel.params.tableInput1 }}</td>
                <td v-show="printModel.params.tableInput2" style="width:20%;">{{ printModel.params.tableInput2 }}</td>
                <td v-show="printModel.params.tableInput3" style="width:20%;">{{ printModel.params.tableInput3 }}</td>
                <td v-show="printModel.params.tableInput4" style="width:20%;">{{ printModel.params.tableInput4 }}</td>
                <td v-show="printModel.params.tableInput5" style="width:20%;">{{ printModel.params.tableInput5 }}</td>
              </tr>
              <tr v-for="(item, tableInputIndex) in printModel.tableInputCustomFieldAttr" :key="tableInputIndex + 1">
                <td v-show="item.tableInput1">{{ item.tableInput1 }}</td>
                <td v-show="item.tableInput2">{{ item.tableInput2 }}</td>
                <td v-show="item.tableInput3">{{ item.tableInput3 }}</td>
                <td v-show="item.tableInput4">{{ item.tableInput4 }}</td>
                <td v-show="item.tableInput5">{{ item.tableInput5 }}</td>
              </tr>
            </tbody>
          </table>
        </template>
        <table style="width: 99%">
          <tbody>
            <tr
              v-show="
                typeof printModel.inclNameArr == 'undefined'
                  ? true
                  : printModel.inclNameArr.length > 0
              "
            >
              <td style="width: 30%"><b>INCI Name</b></td>
              <td style="width: 70%">
                <span
                  t-field="o.inci_name"
                  :key="i"
                  v-for="(tag, i) in printModel.inclNameArr"
                  >{{ tag }}
                  <template v-if="i + 1 < printModel.inclNameArr.length"
                    >,</template
                  >
                </span>
              </td>
            </tr>

            <tr v-show="printModel.params.countryOfOrigin">
              <td style="width: 30%"><b>Country of Origin</b></td>
              <td style="width: 70%">
                <span t-field="o.apperance">{{
                  printModel.params.countryOfOrigin
                }}</span>
              </td>
            </tr>

            <tr v-show="printModel.params.appearance">
              <td style="width: 30%"><b>Appearance</b></td>
              <td style="width: 70%">
                <span t-field="o.apperance">{{
                  printModel.params.appearance
                }}</span>
              </td>
            </tr>

            <tr v-show="printModel.params.solubility">
              <td style="width: 30%"><b>Solubility</b></td>
              <td style="width: 70%">
                <span t-field="o.apperance">{{
                  printModel.params.solubility
                }}</span>
              </td>
            </tr>

            <tr v-show="printModel.params.color">
              <td style="width: 30%"><b>Color</b></td>
              <td style="width: 70%">
                <span t-field="o.apperance">{{ printModel.params.color }}</span>
              </td>
            </tr>

            <tr v-show="printModel.params.odor">
              <td style="width: 30%"><b>Odor</b></td>
              <td style="width: 70%">
                <span t-field="o.apperance">{{ printModel.params.odor }}</span>
              </td>
            </tr>

            <tr v-show="printModel.params.taste">
              <td style="width: 30%"><b>Taste</b></td>
              <td style="width: 70%">
                <span t-field="o.apperance">{{ printModel.params.taste }}</span>
              </td>
            </tr>

            <tr v-show="printModel.params.ehxtoIcip">
              <td style="width: 30%"><b>Important Constituents In Plant</b></td>
              <td style="width: 70%">
                <span t-field="o.apperance">{{
                  printModel.params.ehxtoIcip
                }}</span>
              </td>
            </tr>
            <tr v-show="printModel.params.ehxtoHomogeneity">
              <td style="width: 30%"><b>Homogeneity</b></td>
              <td style="width: 70%">
                <span t-field="o.apperance">{{
                  printModel.params.ehxtoHomogeneity
                }}</span>
              </td>
            </tr>
            <tr v-show="printModel.params.ehxtoExtractionProcess">
              <td style="width: 30%"><b>Extraction Process</b></td>
              <td style="width: 70%">
                <span t-field="o.apperance">{{
                  printModel.params.ehxtoExtractionProcess
                }}</span>
              </td>
            </tr>
            <tr v-show="printModel.params.ehxtoSolventExtraction">
              <td style="width: 30%"><b>Solvent for Extraction</b></td>
              <td style="width: 70%">
                <span t-field="o.apperance">{{
                  printModel.params.ehxtoSolventExtraction
                }}</span>
              </td>
            </tr>
            <tr v-show="printModel.params.ehxtoCarrier">
              <td style="width: 30%"><b>Carrier</b></td>
              <td style="width: 70%">
                <span t-field="o.apperance">{{
                  printModel.params.ehxtoCarrier
                }}</span>
              </td>
            </tr>
            <tr v-show="printModel.params.ehxtoPesticides">
              <td style="width: 30%"><b>Pesticides</b></td>
              <td style="width: 70%">
                <span t-field="o.apperance">{{
                  printModel.params.ehxtoPesticides
                }}</span>
              </td>
            </tr>
            <tr v-show="printModel.params.ehxtoCertification">
              <td style="width: 30%"><b>Certification</b></td>
              <td style="width: 70%">
                <span t-field="o.apperance">{{
                  printModel.params.ehxtoCertification
                }}</span>
              </td>
            </tr>
            <tr v-show="printModel.params.ehxtoCustomerKey1">
              <td style="width: 30%">
                <b>{{ printModel.params.ehxtoCustomerKey1 }}</b>
              </td>
              <td style="width: 70%">
                <span t-field="o.apperance">{{
                  printModel.params.ehxtoCustomerValue1
                }}</span>
              </td>
            </tr>
          </tbody>
          <tbody v-for="(item, genIndex) in printModel.genSpecCustomFieldAttr" :key="genIndex">
            <tr v-show="item.customKey">
              <td style="width: 30%">
                <b>{{ item.customKey }}</b>
              </td>
              <td style="width: 70%">
                <span t-field="o.apperance">{{
                  item.customValue
                }}</span>
              </td>
            </tr>
          </tbody>
        </table>

        <!-- Analysis -->
        <!-- <div style="height: 10px"></div> -->
        <table style="width: 99%">
          <tr>
            <th style="width: 30%">
              <b><u>Tests</u></b>
            </th>
            <th style="width: 35%"><u>Limit</u></th>
            <th style="width: 35%"><u>Test Method</u></th>
          </tr>
          <tbody>
            <tr v-show="printModel.params.testLimitMoisture">
              <td style="width: 30%"><b>Moisture</b></td>
              <td style="width: 35%">
                {{ printModel.params.testLimitMoisture }}
              </td>
              <td style="width: 35%">{{ printModel.params.testMethodMeshSize }}</td>
            </tr>

            <tr v-show="printModel.params.testLimitSpecificaGravity">
              <td style="width: 30%"><b>Specific Gravity</b></td>
              <td style="width: 35%">
                {{ printModel.params.testLimitSpecificaGravity }}
              </td>
              <td style="width: 35%">
                {{ printModel.params.testMethodSpecificaGravity }}
              </td>
            </tr>

            <tr v-show="printModel.params.testLimitRefractiveIndex">
              <td style="width: 30%"><b>Refractive Index</b></td>
              <td style="width: 35%">
                {{ printModel.params.testLimitRefractiveIndex }}
              </td>
              <td style="width: 35%">
                {{ printModel.params.testMethodRefractiveIndex }}
              </td>
            </tr>

            <tr v-show="printModel.params.testLimitOpticalRotation">
              <td style="width: 30%"><b>Optical Rotation</b></td>
              <td style="width: 35%">
                <span t-field="o.standard2">{{
                  printModel.params.testLimitOpticalRotation
                }}</span>
              </td>
              <td style="width: 35%">
                {{ printModel.params.testMethodOpticalRotation }}
              </td>
            </tr>

            <tr v-if="printModel.params.testLimitHeavyMetals">
              <td style="width: 30%"><b>Total Heavy Metals</b></td>
              <td style="width: 35%">
                &lt;{{ printModel.params.testLimitHeavyMetals }} ppm
              </td>
              <td style="width: 35%">
                {{ printModel.params.testMethodHeavyMetals }}
              </td>
            </tr>

            <tr v-if="printModel.params.testLimitArsenic">
              <td style="width: 30%"><b>Arsenic (As)</b></td>
              <td style="width: 35%">
                {{ printModel.params.testLimitArsenic }} ppm
              </td>
              <td style="width: 35%">
                {{ printModel.params.testMethodArsenic }}
              </td>
            </tr>

            <tr v-if="printModel.params.testLimitCadmium">
              <td style="width: 30%"><b>Cadmium (Cd) </b></td>
              <td style="width: 35%">
                {{ printModel.params.testLimitCadmium }} ppm
              </td>
              <td style="width: 35%">
                {{ printModel.params.testMethodCadmium }}
              </td>
            </tr>

            <tr v-if="printModel.params.testLimitLead">
              <td style="width: 30%"><b>Lead (Pd) </b></td>
              <td style="width: 35%">
                {{ printModel.params.testLimitLead }} ppm
              </td>
              <td style="width: 35%">{{ printModel.params.testMethodLead }}</td>
            </tr>

            <tr v-if="printModel.params.testLimitMercury">
              <td style="width: 30%"><b>Mercury (Hg) </b></td>
              <td style="width: 35%">
                {{ printModel.params.testLimitMercury }} ppm
              </td>
              <td style="width: 35%">
                {{ printModel.params.testMethodMercury }}
              </td>
            </tr>

            <tr v-if="printModel.params.ehxtoTestLimitCustomerKey1">
              <td style="width: 30%">
                <b>{{ printModel.params.ehxtoTestLimitCustomerKey1 }} </b>
              </td>
              <td style="width: 30%">
                <b>{{ printModel.params.ehxtoTestLimitCustomerValue1 }} </b>
              </td>
              <td style="width: 35%">
                {{ printModel.params.ehxtoTestMethodCustomerValue1 }}
              </td>
            </tr>

            <tr v-if="printModel.params.ehxtoTestLimitCustomerKey2">
              <td style="width: 30%">
                <b>{{ printModel.params.ehxtoTestLimitCustomerKey2 }} </b>
              </td>
              <td style="width: 30%">
                <b>{{ printModel.params.ehxtoTestLimitCustomerValue2 }} </b>
              </td>
              <td style="width: 35%">
                {{ printModel.params.ehxtoTestMethodCustomerValue2 }}
              </td>
            </tr>
            <tr v-if="printModel.params.ehxtoTestLimitCustomerKey3">
              <td style="width: 30%">
                <b>{{ printModel.params.ehxtoTestLimitCustomerKey3 }} </b>
              </td>
              <td style="width: 30%">
                <b>{{ printModel.params.ehxtoTestLimitCustomerValue3 }} </b>
              </td>
              <td style="width: 35%">
                {{ printModel.params.ehxtoTestMethodCustomerValue3 }}
              </td>
            </tr>
            <tr v-if="printModel.params.ehxtoTestLimitCustomerKey4">
              <td style="width: 30%">
                <b>{{ printModel.params.ehxtoTestLimitCustomerKey4 }} </b>
              </td>
              <td style="width: 30%">
                <b>{{ printModel.params.ehxtoTestLimitCustomerValue4 }} </b>
              </td>
              <td style="width: 35%">
                {{ printModel.params.ehxtoTestMethodCustomerValue4 }}
              </td>
            </tr>
          </tbody>
          <tbody v-for="(item, analysisIndex) in printModel.analysisCustomFieldAttr" :key="analysisIndex">
            <tr v-show="item.customKey">
              <td style="width: 30%">
                <b>{{ item.customKey }}</b>
              </td>
              <td style="width: 35%">
                {{
                  item.customValue
                }}
              </td>
              <td style="width: 35%">
                {{
                  item.customValue1
                }}
              </td>
            </tr>
          </tbody>
        </table>

        <!-- <div style="height: 10px"></div> -->
        <table style="width: 99%">
          <tbody>
            <tr>
              <th style="width: 30%">
                <b><u>Microbiological Tests</u></b>
              </th>
              <th style="width: 35%"><u>Specification</u></th>
              <th style="width: 35%"><u>Test Method</u></th>
            </tr>
            <tr v-show="printModel.params.specificationTotalPlateCount">
              <td style="width: 30%"><b>Total Plate Count</b></td>
              <td style="width: 35%">
                <span t-field="o.total_plate" />&lt;{{
                  printModel.params.specificationTotalPlateCount
                }}
                cfu/g
              </td>
              <td style="width: 35%">
                <span t-field="o.total_plate_test" />{{
                  printModel.params.testMethodTotalPlateCount
                }}
              </td>
            </tr>

            <tr v-show="printModel.params.specificationYeastAndMold">
              <td style="width: 30%"><b>Yeast and Mold</b></td>
              <td style="width: 35%">
                <span t-field="o.yeast_mold" />&lt;{{
                  printModel.params.specificationYeastAndMold
                }}
                cfu/g
              </td>
              <td style="width: 35%">
                <span t-field="o.yeast_mold_test" />{{
                  printModel.params.testMethodYeastAndMold
                }}
              </td>
            </tr>

            <tr v-show="printModel.params.specificationEcoli">
              <td style="width: 30%"><b>E. Coli</b></td>
              <td style="width: 35%">
                <span t-field="o.yeast_mold" />{{
                  printModel.params.specificationEcoli
                }}
              </td>
              <td style="width: 35%">
                <span t-field="o.yeast_mold_test" />{{
                  printModel.params.testMethodEcoli
                }}
              </td>
            </tr>

            <tr v-show="printModel.params.specificationSalmonella">
              <td style="width: 30%"><b>Salmonella</b></td>
              <td style="width: 35%">
                <span t-field="o.yeast_mold" />{{
                  printModel.params.specificationSalmonella
                }}
              </td>
              <td style="width: 35%">
                <span t-field="o.yeast_mold_test" />{{
                  printModel.params.testMethodSalmonella
                }}
              </td>
            </tr>

            <tr v-show="printModel.params.specificationStaphAureus">
              <td style="width: 30%"><b>Staph Aureus</b></td>
              <td style="width: 35%">
                <span t-field="o.yeast_mold" />{{
                  printModel.params.specificationStaphAureus
                }}
              </td>
              <td style="width: 35%">
                <span t-field="o.yeast_mold_test" />{{
                  printModel.params.testMethodTaphAureus
                }}
              </td>
            </tr>

            <tr v-show="printModel.params.specificationTotalColiforms">
              <td style="width: 30%"><b>Total Coliforms</b></td>
              <!-- <td style="width: 35%">
                <span t-field="o.yeast_mold" />&lt;{{
                  printModel.params.specificationTotalColiforms
                }}
                MPN/g
              </td> -->
              <td style="width: 35%" v-if="!printModel.params.specificationTotalColiforms || printModel.params.specificationTotalColiforms.toLowerCase()=='negative'">
                {{ printModel.params.specificationTotalColiforms }}
              </td>
              <td style="width: 35%" v-else>
                &lt;{{ printModel.params.specificationTotalColiforms }} MPN/g
              </td>
              <td style="width: 35%">
                <span t-field="o.yeast_mold_test" />{{
                  printModel.params.testMethodTotalColiforms
                }}
              </td>
            </tr>

            <tr v-if="printModel.params.microbiologicalCustomerKey1">
              <td style="width: 30%">
                <b>{{ printModel.params.microbiologicalCustomerKey1 }}</b>
              </td>
              <td style="width: 35%">
                {{ printModel.params.microbiologicalCustomerValue1 }}
              </td>
              <td style="width: 35%">
                {{ printModel.params.microbiologicalTestMethodCustomerValue1 }}
              </td>
            </tr>
          </tbody>
          
          <tbody v-for="(item, micIndex) in printModel.micTestsCustomFieldAttr" :key="micIndex">
            <tr v-show="item.customKey">
              <td style="width: 30%">
                <b>{{ item.customKey }}</b>
              </td>
              <td style="width: 35%">
                {{
                  item.customValue
                }}
              </td>
              <td style="width: 35%">
                {{
                  item.customValue1
                }}
              </td>
            </tr>
          </tbody>
        </table>

        <!-- <div style="height: 10px"></div> -->
        <table style="width: 99%">
          <tbody>
            <tr>
              <td style="width: 30%; font-size: 18px">
                <b><u>Other Notes</u></b>
              </td>
              <td style="width: 70%" />
            </tr>
            <tr v-if="printModel.params.shelflife">
              <td style="width: 30%"><b>Shelf Life</b></td>
              <td style="width70%">
                <span t-field="o.shelf_life" />{{ printModel.params.shelflife }}
              </td>
            </tr>

            <tr v-if="printModel.params.storage">
              <td style="width: 30%"><b>Storage</b></td>
              <td style="width70%">
                <span t-field="o.storage" />{{ printModel.params.storage }}
              </td>
            </tr>
            <tr v-if="printModel.params.packageInfo.length>0">
              <td style="width: 30%"><b>Package</b></td>
              <td style="width70%">
                <span t-field="o.packageInfo" />{{
                  printModel.params.packageInfo
                }}
              </td>
            </tr>
          </tbody>
        </table>

        <div
          class="footer"
          id="footer"
          style="
            font-size: 11px;
            font-family: Times New Roman;
            margin-top: 10px;
          "
        >
          <div>
            <span>
                <i>
                  * Color variation from batch to batch of the product may occur due to natural variations of raw material.
                </i>
            </span>
          </div>
          <!-- <div style="height: 10px"></div> -->
          <div>
            <span>
                <i>
                  * Some natural sediment may occur upon standing, that does not compromise the quality of the product; please warm to ambient temperature and mix well before use in such case.
                </i>
            </span>
          </div>
          <p v-for="(item, index) in printModel.newNoteArr" :key="index">
            * {{item.content}}
          </p>
          <!-- <div style="height: 10px"></div> -->
          <div>
            <b><u>Disclaimer: The product specifications, limits, test methods, and information contained on this document are accurate to the best of our knowledge based upon general industry information, available scientific data, and customer requests. These values are not intended to be used as specifications for a finished product and should be used only at the sole discretion of the purchaser. According to GMP regulations, the test results of the product must be independently verified by the purchasing party. The appropriate use of this product is the sole responsibility of the user or the purchasing party.</u></b>
          </div>
        </div>
        <FooterSigner></FooterSigner>
      </div>
    </div>
  </div>
</template>
<script>
import FooterSigner from "./FooterSigner";
export default {
  name: "CSFMU",
  components: { FooterSigner },
  props: ["printModel"],
  data() {
    return {
      username: "",
    };
  },
  computed: {
    maxArr() {
      let tmp = [
        this.printModel.commonNameArr.length,
        this.printModel.inclNameArr.length,
        this.printModel.botanicalNameArr.length,
        this.printModel.partUsedArr.length,
      ].sort();
      console.info(tmp[tmp.length - 1]);
      return tmp[tmp.length - 1];
    },
    isShowTable(){
      if(this.printModel.params.tableInput1 || this.printModel.params.tableInput2 || this.printModel.params.tableInput3 || this.printModel.params.tableInput4 || this.printModel.params.tableInput5){
        return true
      }
      return false
    }
  },
  methods: {},
};
</script>

<style scoped>
.tab tr td,
.tab thead th {
  padding: 5px;
}
</style>