import { render, staticRenderFns } from "./REXT.vue?vue&type=template&id=346decbf&scoped=true&"
import script from "./REXT.vue?vue&type=script&lang=js&"
export * from "./REXT.vue?vue&type=script&lang=js&"
import style0 from "./REXT.vue?vue&type=style&index=0&id=346decbf&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "346decbf",
  null
  
)

export default component.exports