<template>
  <div class="page" style="text-align: left" id="pageDiv">
    <div class="row">
      <div style="width: 99%">
        <div class="header" id="header" style="width: 100%; height: 100px">
          <div style="width: 11%; float: left">
            <img
              src="../../assets/print_logo.jpg"
              style="width: 100px; height: 100px"
            />
          </div>
          <div style="width: 89%; float: right">
            <div
              style="
                font-size: 28px;
                font-family: Times New Roman;
                text-align: center;
                vertical-align: text-top;
              "
            >
              <b>Draco Natural Products, Inc. </b>
            </div>
            <div
              style="
                font-size: 28px;
                font-family: Times New Roman;
                text-align: center;
                vertical-align: text-top;
              "
            >
              <b
                ><u
                  >Vital Treasures Bioactive Botanical Extract Specification</u
                ></b
              >
            </div>
          </div>
        </div>

        <!-- Product info -->
        <table style="width: 99%">
          <tr v-show="printModel.params.productName">
            <td style="width: 25%"><b>Product Name</b></td>
            <td style="width: 75%">
              <span t-field="o.name" />{{ printModel.params.productName }}
            </td>
          </tr>
          <tr v-show="printModel.params.versionNumber">
            <td style="width: 25%"><b>Version Number</b></td>
            <td style="width: 75%">
              <span t-field="o.name" />{{ printModel.params.versionNumber }}
            </td>
          </tr>

          <tr v-show="printModel.params.itemCode">
            <td style="width: 25%"><b>Item Code</b></td>
            <td style="width: 75%">
              <span t-field="o.default_code" />{{ printModel.params.itemCode }}
            </td>
          </tr>
          <tr v-show="printModel.params.productDefinition">
            <td style="width: 25%"><b>Product Description</b></td>
            <td style="width: 75%">
              <span t-field="o.description" />{{
                printModel.params.productDefinition
              }}
            </td>
          </tr>
        </table>
        <table style="width: 99%" v-show="printModel.BaseInfoCustomFieldAttr && printModel.BaseInfoCustomFieldAttr.length>0">
          <tr v-for="(baseinfoCustom,baseinfoIndex) in printModel.BaseInfoCustomFieldAttr" :key="baseinfoIndex">
            <td style="width: 25%"><b>{{baseinfoCustom.baseInfoInput1}}</b></td>
            <td style="width: 75%">
              {{ baseinfoCustom.baseInfoInput2 }}
            </td>
          </tr>
        </table>

        <!-- <div style="height: 10px"></div> -->
        <div style="font-size: 18px">
          <b><u>General Specification</u></b>
        </div>
        <!-- 遍历table -->
        <template v-if="printModel.inclNameArr.length > 0">
          <table
            style="width: 98%;border-collapse: collapse;"
            cellspacing="0"
            class="tab"
          >
            <thead>
              <tr style="text-align: left;border-bottom:2px solid gray">
                <th v-show="printModel.commonNameArr.length > 0">Common Name</th>
                <th v-show="printModel.inclNameArr.length > 0">INCI Name</th>
                <th v-show="printModel.botanicalNameArr.length > 0">Botanical Name</th>
                <th v-show="printModel.partUsedArr.length > 0">Part Used</th>
                <th
                  v-show="
                    printModel.percentageArr.length > 0 &&
                    printModel.params.productType != 'Liquid EXT-S'
                  "
                >
                  Percentage
                </th>
              </tr>
            </thead>
            <tbody>
              <tr :key="i" v-for="(tag, i) in maxArr">
                <td v-show="i + 1 <= printModel.commonNameArr.length">
                  {{ printModel.commonNameArr[i] }}
                </td>
                <td v-show="i + 1 <= printModel.inclNameArr.length">
                  {{ printModel.inclNameArr[i] }}
                </td>
                <td v-show="i + 1 <= printModel.botanicalNameArr.length" style="font-style: italic">
                  {{ printModel.botanicalNameArr[i] }}
                </td>
                <td v-show="i + 1 <= printModel.partUsedArr.length">
                  {{ printModel.partUsedArr[i] }}
                </td>
                <td
                  v-show="
                    i + 1 <= printModel.percentageArr.length &&
                    printModel.params.productType != 'Liquid EXT-S'
                  "
                >
                  {{ printModel.percentageArr[i] }}
                </td>
              </tr>
            </tbody>
          </table>
        </template>

        <div v-else-if="isSingle().count == 1">
          <div style="width: 30%; float: left">
            <b>{{ isSingle().name }}</b>
          </div>
          <div style="width: 70%; float: left">
            <div
              style="width: 265px; float: left"
              v-for="(item, val, index) in isSingle().arr"
              :key="index"
            >
              {{ item }}
              <span v-if="val + 1 < isSingle().arr.length" style="">,</span>
            </div>
          </div>
        </div>
        <template v-else>
          <table
            style="width: 98%"
            cellspacing="0"
            class="tab"
          >
            <thead>
              <tr>
                <th v-show="printModel.commonNameArr.length > 0">
                  Common Name
                </th>
                <th v-show="printModel.botanicalNameArr.length > 0">
                  Botanical Name
                </th>
                <th v-show="printModel.partUsedArr.length > 0">Part Used</th>
                <th
                  v-show="
                    printModel.percentageArr.length > 0 &&
                    printModel.params.productType != 'Liquid EXT-S'
                  "
                >
                  Percentage
                </th>
              </tr>
            </thead>
            <tbody>
              <tr :key="i" v-for="(tag, i) in maxArr">
                <td v-show="printModel.commonNameArr.length > 0">
                  <div v-show="i + 1 <= printModel.commonNameArr.length">
                    {{ printModel.commonNameArr[i] }}
                  </div>
                </td>
                <td
                  v-show="printModel.botanicalNameArr.length > 0"
                  style="font-style: italic"
                >
                  <div v-show="i + 1 <= printModel.botanicalNameArr.length">
                    {{ printModel.botanicalNameArr[i] }}
                  </div>
                </td>
                <td v-show="printModel.partUsedArr.length > 0">
                  <div v-show="i + 1 <= printModel.partUsedArr.length">
                    {{ printModel.partUsedArr[i] }}
                  </div>
                </td>
                <td
                  v-show="
                    printModel.percentageArr.length > 0 &&
                    printModel.params.productType != 'Liquid EXT-S'
                  "
                >
                  <div v-show="i + 1 <= printModel.percentageArr.length">
                    {{ printModel.percentageArr[i] }}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </template>
        
        
        <template v-if="isShowTable">
          <table
            style="width: 98%;margin-top:10px;"
            cellspacing="0"
            class="tab"
          >
            <tbody>
              <tr>
                <td v-show="printModel.params.tableInput1" style="width:20%;">{{ printModel.params.tableInput1 }}</td>
                <td v-show="printModel.params.tableInput2" style="width:20%;">{{ printModel.params.tableInput2 }}</td>
                <td v-show="printModel.params.tableInput3" style="width:20%;">{{ printModel.params.tableInput3 }}</td>
                <td v-show="printModel.params.tableInput4" style="width:20%;">{{ printModel.params.tableInput4 }}</td>
                <td v-show="printModel.params.tableInput5" style="width:20%;">{{ printModel.params.tableInput5 }}</td>
              </tr>
              <tr v-for="(item, tableInputIndex) in printModel.tableInputCustomFieldAttr" :key="tableInputIndex + 1">
                <td v-show="item.tableInput1">{{ item.tableInput1 }}</td>
                <td v-show="item.tableInput2">{{ item.tableInput2 }}</td>
                <td v-show="item.tableInput3">{{ item.tableInput3 }}</td>
                <td v-show="item.tableInput4">{{ item.tableInput4 }}</td>
                <td v-show="item.tableInput5">{{ item.tableInput5 }}</td>
              </tr>
            </tbody>
          </table>
        </template>

        <table style="width: 99%">
          <tbody>
            <tr v-show="printModel.params.majorConstituentsInNativeHerbs">
              <td style="width: 35%">
                <b>Major Constituents in Native Herbs</b>
              </td>
              <td style="width: 65%">
                <span t-field="o.apperance">{{
                  printModel.params.majorConstituentsInNativeHerbs
                }}</span>
              </td>
            </tr>

            <tr v-show="printModel.params.color">
              <td style="width: 35%"><b>Color</b></td>
              <td style="width: 65%">
                <span t-field="o.apperance">{{ printModel.params.color }}</span>
              </td>
            </tr>

            <tr v-show="printModel.params.odor">
              <td style="width: 35%"><b>Odor</b></td>
              <td style="width: 65%">
                <span t-field="o.apperance">{{ printModel.params.odor }}</span>
              </td>
            </tr>

            <tr v-show="printModel.params.taste">
              <td style="width: 35%"><b>Taste</b></td>
              <td style="width: 65%">
                <span t-field="o.apperance">{{ printModel.params.taste }}</span>
              </td>
            </tr>

            <tr v-show="printModel.params.carrierSolvent">
              <td style="width: 35%"><b>Carrier</b></td>
              <td style="width: 65%">
                <span t-field="o.apperance"
                  >{{ printModel.params.carrierSolvent }}
                </span>
              </td>
            </tr>

            <!-- <tr v-show="printModel.preservativeArr.length>0">
                <td style="width:30%"><b>Preservative</b></td>
                <td style="width:70%">
                    <span t-field="o.apperance" :key="i" v-for="(tag,i) in printModel.preservativeArr">{{tag}}
                    <template v-if="i+1<printModel.preservativeArr.length">,</template>
                    </span>
                    </td>
            </tr> -->

            <tr v-show="printModel.params.preservative">
              <td style="width: 35%"><b>Preservative</b></td>
              <td style="width: 65%">
                <span t-field="o.apperance">{{
                  printModel.params.preservative
                }}</span>
              </td>
            </tr>

            <tr v-show="printModel.params.generalSpecificationKey1">
              <td style="width: 35%">
                <b>{{ printModel.params.generalSpecificationKey1 }}</b>
              </td>
              <td style="width: 65%">
                <span t-field="o.apperance">{{
                  printModel.params.generalSpecificationValue1
                }}</span>
              </td>
            </tr>

            <tr v-show="printModel.params.generalSpecificationKey2">
              <td style="width: 35%">
                <b>{{ printModel.params.generalSpecificationKey2 }}</b>
              </td>
              <td style="width: 65%">
                <span t-field="o.apperance">{{
                  printModel.params.generalSpecificationValue2
                }}</span>
              </td>
            </tr>

            <tr v-show="printModel.params.generalSpecificationKey3">
              <td style="width: 35%">
                <b>{{ printModel.params.generalSpecificationKey3 }}</b>
              </td>
              <td style="width: 65%">
                <span t-field="o.apperance">{{
                  printModel.params.generalSpecificationValue3
                }}</span>
              </td>
            </tr>
          </tbody>
          <tbody v-for="(item, genIndex) in printModel.genSpecCustomFieldAttr" :key="genIndex">
            <tr v-show="item.customKey">
              <td style="width: 35%">
                <b>{{ item.customKey }}</b>
              </td>
              <td style="width: 65%">
                <span t-field="o.apperance">{{
                  item.customValue
                }}</span>
              </td>
            </tr>
          </tbody>
        </table>

        <!-- Analysis -->
        <!-- <div style="height: 10px"></div> -->
        <table style="width: 99%">
          <tbody>
            <tr>
              <td style="width: 30%">
                <b><u>Analysis</u></b>
              </td>
              <td style="width: 35%">
                <b><u>Range</u></b>
              </td>
              <td style="width: 35%">
                <b><u>Test Method</u></b>
              </td>
            </tr>
            <tr v-show="printModel.params.analysisRangeBioactivesLevel">
              <td style="width: 30%"><b>Bioactives level</b></td>
              <td style="width: 35%">
                <span v-if="printModel.params.productType=='SDNP-L'">≥</span>{{ printModel.params.analysisRangeBioactivesLevel }}%
              </td>
              <td style="width: 35%"></td>
            </tr>
            <tr v-show="printModel.params.liquidExtCustomerKey1">
              <td style="width: 35%">
                <b>{{ printModel.params.liquidExtCustomerKey1 }}</b>
              </td>
              <td style="width: 35%">
                {{ printModel.params.liquidExtCustomerValue1 }}
              </td>
              <td style="width: 35%">
                {{ printModel.params.liquidExtCustomerValue2 }}
              </td>
            </tr>
            <tr v-show="printModel.params.liquidExtCustomerKey3">
              <td style="width: 35%">
                <b>{{ printModel.params.liquidExtCustomerKey3 }}</b>
              </td>
              <td style="width: 35%">
                {{ printModel.params.liquidExtCustomerValue3 }}
              </td>
              <td style="width: 35%">
                {{ printModel.params.liquidExtCustomerValue3TM }}
              </td>
            </tr>
            <tr v-show="printModel.params.liquidExtCustomerKey4">
              <td style="width: 35%">
                <b>{{ printModel.params.liquidExtCustomerKey4 }}</b>
              </td>
              <td style="width: 35%">
                {{ printModel.params.liquidExtCustomerValue4 }}
              </td>
              <td style="width: 35%">
                {{ printModel.params.liquidExtCustomerValue4TM }}
              </td>
            </tr>
            <tr v-show="printModel.params.liquidExtCustomerKey5">
              <td style="width: 35%">
                <b>{{ printModel.params.liquidExtCustomerKey5 }}</b>
              </td>
              <td style="width: 35%">
                {{ printModel.params.liquidExtCustomerValue5 }}
              </td>
              <td style="width: 35%">
                {{ printModel.params.liquidExtCustomerValue5TM }}
              </td>
            </tr>
            
            <tr v-for="(analysis, analysisIndex) in printModel.analysisCustomFieldAttr" :key="analysisIndex">
              <td v-show="analysis.customKey" style="width: 30%">
                <b>{{ analysis.customKey }}</b>
              </td>
              <td v-show="analysis.customKey" style="width: 35%">
                {{
                  analysis.customValue
                }}
              </td>
              <td v-show="analysis.customKey" style="width: 35%">
                {{
                  analysis.customValue1
                }}
              </td>
            </tr>

            
            <tr v-show="printModel.params.analysisRangePh">
              <td style="width: 30%"><b>pH</b></td>
              <td style="width: 35%">
                {{ printModel.params.analysisRangePh }}
              </td>
              <td style="width: 35%">USP&lt;791&gt;</td>
            </tr>
            <tr>
              <td style="width: 30%"><b>Heavy Metal</b></td>
              <td style="width: 35%">Conforms to USP&lt;231&gt;</td>
              <td style="width: 35%">USP&lt;231&gt;</td>
            </tr>
            <tr v-show="printModel.params.analysisArsenic">
              <td style="width: 30%"><b>Arsenic</b></td>
              <td style="width: 35%">&lt;{{ printModel.params.analysisArsenic }}ppm</td>
              <td style="width: 35%">{{ printModel.params.analysisMethodArsenic }}</td>
            </tr>
            <tr v-show="printModel.params.analysisCadmium">
              <td style="width: 30%"><b>Cadmium</b></td>
              <td style="width: 35%">&lt;{{ printModel.params.analysisCadmium }}ppm</td>
              <td style="width: 35%">{{ printModel.params.analysisMethodCadmium }}</td>
            </tr>
            <tr v-show="printModel.params.analysisLead">
              <td style="width: 30%"><b>Lead</b></td>
              <td style="width: 35%">&lt;{{ printModel.params.analysisLead }}ppm</td>
              <td style="width: 35%">{{ printModel.params.analysisMethodLead }}</td>
            </tr>
            <tr v-show="printModel.params.analysisMercury">
              <td style="width: 30%"><b>Mercury</b></td>
              <td style="width: 35%">&lt;{{ printModel.params.analysisMercury }}ppm</td>
              <td style="width: 35%">{{ printModel.params.analysisMethodMercury }}</td>
            </tr>

            <tr>
              <td style="width: 30%"><b>Pesticide</b></td>
              <td style="width: 35%">
                <span t-field="o.standard2">Conforms to USP&lt;565&gt;</span>
              </td>
              <td style="width: 35%">USP&lt;565&gt;</td>
            </tr>
            <tr v-if="printModel.params.analysisRangeTotalPlateCount">
              <td style="width: 30%"><b>Total Plate Count</b></td>
              <td style="width: 35%">
                &lt;{{ printModel.params.analysisRangeTotalPlateCount }}cfu/g
              </td>
              <td style="width: 35%">FDA-BAM</td>
            </tr>

            <tr>
              <td style="width: 30%"><b>Yeast & Mold</b></td>
              <td style="width: 35%">&lt;{{printModel.params.analysisRangeYeastMold}} cfu/g</td>
              <td style="width: 35%">FDA-BAM</td>
            </tr>
            <tr v-if="printModel.params.specificationEcoli">
              <td style="width: 30%"><b>E. Coli</b></td>
              <td style="width: 35%">
                {{ printModel.params.specificationEcoli
                }}<span
                  v-if="printModel.params.specificationEcoli != 'Negative'"
                  >cfu/g</span
                >
              </td>
              <td style="width: 35%">FDA-BAM</td>
            </tr>
            <tr v-if="printModel.params.specificationSalmonella">
              <td style="width: 30%"><b>Salmonella</b></td>
              <td style="width: 35%">
                {{ printModel.params.specificationSalmonella
                }}<span
                  v-if="printModel.params.specificationSalmonella != 'Negative'"
                  >cfu/g</span
                >
              </td>
              <td style="width: 35%">FDA-BAM</td>
            </tr>
            <tr v-if="printModel.params.specificationStaphAureus">
              <td style="width: 30%"><b>Staph Aureus</b></td>
              <td style="width: 35%">
                {{ printModel.params.specificationStaphAureus
                }}<span
                  v-if="
                    printModel.params.specificationStaphAureus != 'Negative'
                  "
                  >cfu/g</span
                >
              </td>
              <td style="width: 35%">FDA-BAM</td>
            </tr>
            <tr v-if="printModel.params.testMethodTotalColiforms">
              <td style="width: 30%"><b>Total Coliform</b></td>
              <!-- <td style="width: 35%">
                <span t-field="o.yeast_mold" />&lt;{{
                  printModel.params.specificationTotalColiforms
                }}
                MPN/g
              </td> -->
              <td style="width: 35%" v-if="!printModel.params.testMethodTotalColiforms || printModel.params.testMethodTotalColiforms.toLowerCase()=='negative'">
                {{ printModel.params.testMethodTotalColiforms }}
              </td>
              <td style="width: 35%" v-else>
                &lt;{{ printModel.params.testMethodTotalColiforms }} MPN/g
              </td>
              <td style="width: 35%">FDA-BAM</td>
            </tr>
          </tbody>
        </table>

        <!-- <div style="height: 10px"></div> -->
        <table style="width: 99%">
          <tbody>
            <tr>
              <td style="width: 35%; font-size: 18px">
                <b><u>Other Notes</u></b>
              </td>
              <td style="width: 65%" />
            </tr>
            <tr v-show="printModel.params.shelflife">
              <td style="width: 35%"><b>Shelf Life</b></td>
              <td style="width: 65%">
                <span t-field="o.shelf_life" />{{ printModel.params.shelflife }}
              </td>
            </tr>

            <tr v-if="printModel.params.storage">
              <td style="width: 35%"><b>Storage</b></td>
              <td style="width: 65%">
                <span t-field="o.storage" />{{ printModel.params.storage }}
              </td>
            </tr>
          </tbody>
        </table>
        <div
          class="footer"
          id="footer"
          style="
            font-size: 11px;
            font-family: Times New Roman;
            margin-top: 10px;
          "
        >
          <p>
            * Color variation from batch to batch of the product may occur due to natural variations of raw material.
          </p>
          <p>
            * Some natural sediment may occur upon standing, that does not compromise the quality of the product; please warm to ambient temperature and mix well before use in such case.
          </p>
          <p>
            * The pH of liquid product should be tested in its 1% aqueous solution if the liquid product contains less than 50% water.
          </p>
          <p v-for="(item, index) in printModel.newNoteArr" :key="index">
            * {{item.content}}
          </p>
          <!-- <div style="height: 10px"></div> -->
          <div>
            <b><u>Disclaimer: The product specifications, limits, test methods, and information contained on this document are accurate to the best of our knowledge based upon general industry information, available scientific data, and customer requests. These values are not intended to be used as specifications for a finished product and should be used only at the sole discretion of the purchaser. According to GMP regulations, the test results of the product must be independently verified by the purchasing party. The appropriate use of this product is the sole responsibility of the user or the purchasing party.</u></b>
          </div>
        </div>
        <FooterSigner></FooterSigner>
      </div>
    </div>
  </div>
</template>
<script>
import FooterSigner from "./FooterSigner";
export default {
  name: "CSFMU",
  components: { FooterSigner },
  props: ["printModel"],
  data() {
    return {
      username: "",
      imageUrl: "",
    };
  },
  computed: {
    maxArr() {
      let tmp = [
        this.printModel.ingredientsArr.length,
        this.printModel.inclNameArr.length,
        this.printModel.botanicalNameArr.length,
        this.printModel.commonNameArr.length,
        this.printModel.partUsedArr.length,
      ].sort();
      return tmp[tmp.length - 1];
    },
    isShowTable(){
      if(this.printModel.params.tableInput1 || this.printModel.params.tableInput2 || this.printModel.params.tableInput3 || this.printModel.params.tableInput4 || this.printModel.params.tableInput5){
        return true
      }
      return false
    }
  },
  methods: {
    isSingle() {
      var count = 0;
      var name = "";
      var arr = [];
      if (this.printModel.commonNameArr.length >= 1) {
        name = "Common/Other Name";
        count = count + 1;
        arr = this.printModel.commonNameArr;
      }
      if (this.printModel.botanicalNameArr.length >= 1) {
        name = "Botanical Name";
        count = count + 1;
        arr = this.printModel.botanicalNameArr;
      }
      if (this.printModel.partUsedArr.length >= 1) {
        name = "Part Used";
        count = count + 1;
        arr = this.printModel.partUsedArr;
      }
      if (this.printModel.params.productType != "Liquid EXT-S") {
        if (this.printModel.percentageArr.length >= 1) {
          name = "Percentage";
          count = count + 1;
          arr = this.printModel.percentageArr;
        }
      }
      let res = { count, name, arr };
      console.log("this.printModel");
      console.log(this.printModel);
      return res;
    },
  },
  mounted() {
    if (this.printModel.params.confidential == true) {
      // var pageDiv=document.getElementById("pageDiv");
      // pageDiv.style.backgroundImage="url("+this.printModel.imageUrl+")";
    }
  },
};
</script>

<style scoped>
.tab tr td,
.tab thead th {
  padding: 5px;
}
</style>