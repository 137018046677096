<template>
  <div class="page" style="text-align: left">
    <div class="row">
      <div style="width: 99%">
        <div class="header" id="header" style="width: 100%; height: 100px">
          <div style="width: 11%; float: left">
            <img
              src="../../assets/print_logo.jpg"
              style="width: 100px; height: 100px"
            />
          </div>
          <div style="width: 89%; float: right">
            <div
              style="
                font-size: 28px;
                font-family: Times New Roman;
                text-align: center;
                vertical-align: text-top;
              "
            >
              <b>Draco Natural Products, Inc. </b>
            </div>
            <div
              style="
                font-size: 28px;
                font-family: Times New Roman;
                text-align: center;
                vertical-align: text-top;
              "
            >
              <b><u>PRODUCT SPECIFICATION</u></b>
            </div>
            <div
              style="
                font-size: 28px;
                font-family: Times New Roman;
                text-align: center;
                vertical-align: text-top;
              "
            >
              <b>Action Synergized Formulas™ Powdered Extracts</b>
            </div>
          </div>
        </div>

        <!-- Product info -->
        <table style="width: 99%">
          <tr v-show="printModel.params.productName.length > 0">
            <td style="width: 25%"><b>Product Name</b></td>
            <td style="width: 75%">
              <span t-field="o.name" />{{ printModel.params.productName }}
            </td>
          </tr>
          <tr v-show="printModel.params.versionNumber">
            <td style="width: 25%"><b>Version Number</b></td>
            <td style="width: 75%">
              <span t-field="o.name" />{{ printModel.params.versionNumber }}
            </td>
          </tr>

          <tr v-show="printModel.params.itemCode.length > 0">
            <td style="width: 25%"><b>Item Code</b></td>
            <td style="width: 75%">
              <span t-field="o.default_code" />{{ printModel.params.itemCode }}
            </td>
          </tr>
          <tr v-show="printModel.params.productDescription.length > 0">
            <td style="width: 25%"><b>Product Description</b></td>
            <td style="width: 75%">
              <div t-field="o.description">
                {{ printModel.params.productDescription }}
              </div>
            </td>
          </tr>
        </table>
        <table style="width: 99%" v-show="printModel.BaseInfoCustomFieldAttr && printModel.BaseInfoCustomFieldAttr.length>0">
          <tr v-for="(baseinfoCustom,baseinfoIndex) in printModel.BaseInfoCustomFieldAttr" :key="baseinfoIndex">
            <td style="width: 25%"><b>{{baseinfoCustom.baseInfoInput1}}</b></td>
            <td style="width: 75%">
              {{ baseinfoCustom.baseInfoInput2 }}
            </td>
          </tr>
        </table>

        <!-- <div style="height: 10px"></div> -->
        <div style="font-size: 18px">
          <b><u>General Specification</u></b>
        </div>

        <!-- 遍历table -->
        <template v-if="printModel.inclNameArr.length > 0">
          <table
            style="width: 98%;border-collapse: collapse;"
            cellspacing="0"
            class="tab"
          >
            <thead>
              <tr style="text-align: left;border-bottom:2px solid gray">
                <!-- <th v-show="typeof(printModel.ingredientsArr) == undefined ? true : printModel.ingredientsArr.length>0">
                                                Ingredients
                                            </th> -->
                <th v-show="printModel.commonNameArr.length > 0">Common Name</th>
                <th v-show="printModel.inclNameArr.length > 0">INCI Name</th>
                <th v-show="printModel.botanicalNameArr.length > 0">Botanical Name</th>
                <th v-show="printModel.partUsedArr.length > 0">Part Used</th>
              </tr>
            </thead>
            <tbody>
              <tr :key="i" v-for="(tag, i) in maxArr">
                <!-- <td v-show="(i+1)<=printModel.ingredientsArr.length">{{printModel.ingredientsArr[i]}}</td> -->
                <td v-show="i + 1 <= printModel.commonNameArr.length">
                  {{ printModel.commonNameArr[i] }}
                </td>
                <td v-show="i + 1 <= printModel.inclNameArr.length">
                  {{ printModel.inclNameArr[i] }}
                </td>
                <td v-show="i + 1 <= printModel.botanicalNameArr.length" style="font-style: italic">
                  {{ printModel.botanicalNameArr[i] }}
                </td>
                <td v-show="i + 1 <= printModel.partUsedArr.length">
                  {{ printModel.partUsedArr[i] }}
                </td>
              </tr>
            </tbody>
          </table>
        </template>

        <div v-else-if="isSingle().count == 1">
          <b>{{ isSingle().name }}</b
          ><span style="margin-left: 20px"></span>
          <span v-for="(item, val, index) in isSingle().arr" :key="index"
            >{{ item
            }}<span v-if="val + 1 < isSingle().arr.length">, </span></span
          >
        </div>
        <template v-else>
          <table
            style="width: 98%;border-collapse: collapse;"
            cellspacing="0"
            class="tab"
          >
            <thead>
              <tr style="text-align: left;border-bottom:2px solid gray">
                <th v-show="printModel.commonOtherNameArr.length > 0">
                  Common Name
                </th>
                <th v-show="printModel.botanicalNameArr.length > 0">
                  Botanical Name
                </th>
                <th v-show="printModel.partUsedArr.length > 0">Part Used</th>
                <th v-show="printModel.percentageArr.length > 0">Percentage</th>
              </tr>
            </thead>
            <tbody>
              <tr :key="i" v-for="(tag, i) in maxArr">
                <td v-show="printModel.commonOtherNameArr.length > 0">
                  <div v-show="i + 1 <= printModel.commonOtherNameArr.length">
                    {{ printModel.commonOtherNameArr[i] }}
                  </div>
                </td>
                <td
                  v-show="printModel.botanicalNameArr.length > 0"
                  style="font-style: italic"
                >
                  <div v-show="i + 1 <= printModel.botanicalNameArr.length">
                    {{ printModel.botanicalNameArr[i] }}
                  </div>
                </td>
                <td v-show="printModel.partUsedArr.length > 0">
                  <div v-show="i + 1 <= printModel.partUsedArr.length">
                    {{ printModel.partUsedArr[i] }}
                  </div>
                </td>
                <td v-show="printModel.percentageArr.length > 0">
                  <div v-show="i + 1 <= printModel.percentageArr.length">
                    {{ printModel.percentageArr[i] }}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </template>

        
        <template v-if="isShowTable">
          <table
            style="width: 98%;margin-top:10px;"
            cellspacing="0"
            class="tab"
          >
            <tbody>
              <tr>
                <td v-show="printModel.params.tableInput1" style="width:20%;">{{ printModel.params.tableInput1 }}</td>
                <td v-show="printModel.params.tableInput2" style="width:20%;">{{ printModel.params.tableInput2 }}</td>
                <td v-show="printModel.params.tableInput3" style="width:20%;">{{ printModel.params.tableInput3 }}</td>
                <td v-show="printModel.params.tableInput4" style="width:20%;">{{ printModel.params.tableInput4 }}</td>
                <td v-show="printModel.params.tableInput5" style="width:20%;">{{ printModel.params.tableInput5 }}</td>
              </tr>
              <tr v-for="(item, tableInputIndex) in printModel.tableInputCustomFieldAttr" :key="tableInputIndex + 1">
                <td v-show="item.tableInput1">{{ item.tableInput1 }}</td>
                <td v-show="item.tableInput2">{{ item.tableInput2 }}</td>
                <td v-show="item.tableInput3">{{ item.tableInput3 }}</td>
                <td v-show="item.tableInput4">{{ item.tableInput4 }}</td>
                <td v-show="item.tableInput5">{{ item.tableInput5 }}</td>
              </tr>
            </tbody>
          </table>
        </template>
        
        <!-- <div v-if="printModel.commonOtherNameArr.length>0&&printModel.botanicalNameArr.length==0&&printModel.partUsedArr.length==0">
                                <span v-for="(item,val,index) in printModel.commonOtherNameArr" :key="index" >{{item}} <span v-if="val+1<printModel.commonOtherNameArr.length">,</span></span>
                            </div> -->

        <table style="width: 99%">
          <tbody>
            <tr v-show="printModel.params.extractionRatio">
              <td style="width: 30%"><b>Extraction Ratio</b></td>
              <td style="width: 70%">
                <span t-field="o.apperance">{{
                  printModel.params.extractionRatio
                }}</span>
              </td>
            </tr>
            <tr v-show="printModel.params.processingAid">
              <td style="width: 30%"><b>processingAid</b></td>
              <td style="width: 70%">
                <span t-field="o.apperance">{{
                  printModel.params.processingAid
                }}</span>
              </td>
            </tr>

            <tr v-show="printModel.params.importantConstitueInPlant">
              <td style="width: 30%"><b>Important Constituents In Plant</b></td>
              <td style="width: 70%">
                <span t-field="o.apperance">{{
                  printModel.params.importantConstitueInPlant
                }}</span>
              </td>
            </tr>

            <tr v-show="printModel.params.appearance">
              <td style="width: 30%"><b>Appearance</b></td>
              <td style="width: 70%">
                <span t-field="o.apperance">{{
                  printModel.params.appearance
                }}</span>
              </td>
            </tr>

            <tr v-show="printModel.params.solubility">
              <td style="width: 30%"><b>Solubility</b></td>
              <td style="width: 70%">
                <span t-field="o.apperance">{{
                  printModel.params.solubility
                }}</span>
              </td>
            </tr>

            <tr>
              <td style="width: 30%"><b>Homogeneity</b></td>
              <td style="width: 70%">
                <span t-field="o.apperance">Completely homogenous</span>
              </td>
            </tr>

            <tr v-show="printModel.params.ehxtoExtractionProcess">
              <td style="width: 30%"><b>Extraction Process</b></td>
              <td style="width: 70%">
                <span t-field="o.apperance">{{
                  printModel.params.ehxtoExtractionProcess
                }}</span>
              </td>
            </tr>
            <!-- {{printModel.params.solventForExtracti}}{{typeof(printModel.params.solventForExtracti)}} -->

            <tr v-show="printModel.params.solventForExtracti">
              <td style="width: 30%"><b>Solvent for Extraction</b></td>
              <td style="width: 70%">
                <span t-field="o.apperance">{{
                  printModel.params.solventForExtracti
                }}</span>
              </td>
            </tr>

            <tr v-show="printModel.params.carrier">
              <td style="width: 30%"><b>Carrier</b></td>
              <td style="width: 70%">
                <span t-field="o.apperance">{{
                  printModel.params.carrier
                }}</span>
              </td>
            </tr>

            <tr v-show="printModel.params.pesticides">
              <td style="width: 30%"><b>Pesticides</b></td>
              <td style="width: 70%">
                <span t-field="o.apperance">{{
                  printModel.params.pesticides
                }}</span>
              </td>
            </tr>

            <tr v-show="printModel.params.certification">
              <td style="width: 30%"><b>Certification</b></td>
              <td style="width: 70%">
                <span t-field="o.apperance">{{
                  printModel.params.certification
                }}</span>
              </td>
            </tr>
            <tr v-show="printModel.params.generalSpecificationKey1">
              <td style="width: 30%">
                <b>{{ printModel.params.generalSpecificationKey1 }}</b>
              </td>
              <td style="width: 70%">
                <span t-field="o.apperance">{{
                  printModel.params.generalSpecificationValue1
                }}</span>
              </td>
            </tr>
            <tr v-show="printModel.params.generalSpecificationKey2">
              <td style="width: 30%">
                <b>{{ printModel.params.generalSpecificationKey2 }}</b>
              </td>
              <td style="width: 70%">
                <span t-field="o.apperance">{{
                  printModel.params.generalSpecificationValue2
                }}</span>
              </td>
            </tr>
            <tr v-show="printModel.params.generalSpecificationKey3">
              <td style="width: 30%">
                <b>{{ printModel.params.generalSpecificationKey3 }}</b>
              </td>
              <td style="width: 70%">
                <span t-field="o.apperance">{{
                  printModel.params.generalSpecificationValue3
                }}</span>
              </td>
            </tr>
          </tbody>
          <tbody v-for="(item, genIndex) in printModel.genSpecCustomFieldAttr" :key="genIndex">
            <tr v-show="item.customKey">
              <td style="width: 30%">
                <b>{{ item.customKey }}</b>
              </td>
              <td style="width: 70%">
                <span t-field="o.apperance">{{
                  item.customValue
                }}</span>
              </td>
            </tr>
          </tbody>
        </table>

        <!-- Analysis -->
        <!-- <div style="height: 10px"></div> -->
        <table style="width: 99%">
          <tbody>
            <tr>
              <th style="width: 30%">
                <b><u>Analysis</u></b>
              </th>
              <th style="width: 35%"><u>Specification</u></th>
              <th style="width: 35%"><u>Test Method</u></th>
            </tr>
            <tr v-show="printModel.params.specificationStandard1">
              <td style="width: 30%" v-if="!printModel.params.specificationStandard2 && !printModel.params.specificationStandard3"><b>STANDARD</b></td>
              <td style="width: 30%" v-else><b>STANDARD 1</b></td>
              <td style="width: 35%">
                {{ printModel.params.specificationStandard1 }}
              </td>
              <td style="width: 35%">
                {{ printModel.params.testMethodStandard1 }}
              </td>
            </tr>

            <tr v-show="printModel.params.specificationStandard2">
              <td style="width: 30%" v-if="!printModel.params.specificationStandard1 && !printModel.params.specificationStandard3"><b>STANDARD</b></td>
              <td style="width: 30%" v-else-if="!printModel.params.specificationStandard1 && printModel.params.specificationStandard3"><b>STANDARD 1</b></td>
              <td style="width: 30%" v-else><b>STANDARD 2</b></td>
              <td style="width: 35%">
                {{ printModel.params.specificationStandard2 }}
              </td>
              <td style="width: 35%">
                {{ printModel.params.testMethodStandard2 }}
              </td>
            </tr>

            <tr v-show="printModel.params.specificationStandard3">
              <td style="width: 30%" v-if="!printModel.params.specificationStandard1 && !printModel.params.specificationStandard2"><b>STANDARD</b></td>
              <td style="width: 30%" v-else-if="printModel.params.specificationStandard1 || printModel.params.specificationStandard2"><b>STANDARD 2</b></td>
              <td style="width: 30%" v-else><b>STANDARD 3</b></td>
              <td style="width: 35%">
                {{ printModel.params.specificationStandard3 }}
              </td>
              <td style="width: 35%">
                {{ printModel.params.testMethodStandard3 }}
              </td>
            </tr>

            <tr v-show="printModel.params.specificationMeshSize">
              <td style="width: 30%"><b>Mesh Size</b></td>
              <td style="width: 35%">
                <span t-field="o.standard2">{{
                  printModel.params.specificationMeshSize
                }}</span>
              </td>
              <td style="width: 35%">Metric Sieve</td>
            </tr>

            <tr v-show="printModel.params.specificationLossonDrying">
              <td style="width: 30%"><b>Loss on Drying</b></td>
              <td style="width: 35%">
                &lt;{{ printModel.params.specificationLossonDrying }} %
              </td>
              <td style="width: 35%">Draco-1.1.1.0</td>
            </tr>

            <tr v-show="printModel.params.specificationBulkDensity">
              <td style="width: 30%"><b>Bulk Density</b></td>
              <td style="width: 35%">
                {{ printModel.params.specificationBulkDensity }} g/mL
              </td>
              <td style="width: 35%">USP &lt;616&gt;</td>
            </tr>

            <tr>
              <td style="width: 30%"><b>Identification</b></td>
              <td style="width: 35%">Conforms</td>
              <td style="width: 35%">HPTLC</td>
            </tr>
            <tr v-show="printModel.params.specificationCustomerKey1">
              <td style="width: 30%">
                <b>{{ printModel.params.specificationCustomerKey1 }}</b>
              </td>
              <td style="width: 35%">
                {{ printModel.params.specificationCustomerValue1 }}
              </td>
              <td
                style="width: 35%"
                v-show="
                  typeof printModel.params.testMethodCustomerValue1 !=
                  'undefined'
                "
              >
                {{ printModel.params.testMethodCustomerValue1 }}
              </td>
            </tr>
            
            <tr v-for="(analysis, analysisIndex) in printModel.analysisCustomFieldAttr" :key="analysisIndex">
              <td v-show="analysis.customKey" style="width: 30%">
                <b>{{ analysis.customKey }}</b>
              </td>
              <td v-show="analysis.customKey" style="width: 35%">
                {{
                  analysis.customValue
                }}
              </td>
              <td v-show="analysis.customKey" style="width: 35%">
                {{
                  analysis.customValue1
                }}
              </td>
            </tr>

            <tr v-show="printModel.params.specificationTotalHeavyMetals">
              <td style="width: 30%"><b>Total Heavy Metals</b></td>
              <td style="width: 35%">
                &lt;{{ printModel.params.specificationTotalHeavyMetals }} ppm
              </td>
              <td style="width: 35%">USP &lt;231&gt;</td>
            </tr>

            <tr v-if="printModel.params.specificationArsenic">
              <td style="width: 30%"><b>Arsenic</b></td>
              <td style="width: 35%">
                {{ printModel.params.specificationArsenic }} ppm
              </td>
              <td style="width: 35%">
                {{ printModel.params.testMethodArsenic }}
              </td>
            </tr>
            <tr v-if="printModel.params.specificationCadmium">
              <td style="width: 30%"><b>Cadmium</b></td>
              <td style="width: 35%">
                {{ printModel.params.specificationCadmium }} ppm
              </td>
              <td style="width: 35%">
                {{ printModel.params.testMethodCadmium }}
              </td>
            </tr>
            <tr v-if="printModel.params.specificationLead">
              <td style="width: 30%"><b>Lead</b></td>
              <td style="width: 35%">
                {{ printModel.params.specificationLead }} ppm
              </td>
              <td style="width: 35%">{{ printModel.params.testMethodLead }}</td>
            </tr>
            <tr v-if="printModel.params.specificationMercury">
              <td style="width: 30%"><b>Mercury</b></td>
              <td style="width: 35%">
                {{ printModel.params.specificationMercury }} ppm
              </td>
              <td style="width: 35%">
                {{ printModel.params.testMethodMercury }}
              </td>
            </tr>

            <tr v-show="printModel.params.specificationCustomerKey2">
              <td style="width: 30%">
                <b>{{ printModel.params.specificationCustomerKey2 }}</b>
              </td>
              <td style="width: 35%">
                {{ printModel.params.specificationCustomerValue2 }}
              </td>
              <td
                style="width: 35%"
                v-show="
                  typeof printModel.params.testMethodCustomerValue2 !=
                  'undefined'
                "
              >
                {{ printModel.params.testMethodCustomerValue2 }}
              </td>
            </tr>
            <tr v-show="printModel.params.specificationCustomerKey3">
              <td style="width: 30%">
                <b>{{ printModel.params.specificationCustomerKey3 }}</b>
              </td>
              <td style="width: 35%">
                {{ printModel.params.specificationCustomerValue3 }}
              </td>
              <td
                style="width: 35%"
                v-show="
                  typeof printModel.params.testMethodCustomerValue3 !=
                  'undefined'
                "
              >
                {{ printModel.params.testMethodCustomerValue3 }}
              </td>
            </tr>
            <tr v-show="printModel.params.specificationCustomerKey4">
              <td style="width: 30%">
                <b>{{ printModel.params.specificationCustomerKey4 }}</b>
              </td>
              <td style="width: 35%">
                {{ printModel.params.specificationCustomerValue4 }}
              </td>
              <td
                style="width: 35%"
                v-show="
                  typeof printModel.params.testMethodCustomerValue4 !=
                  'undefined'
                "
              >
                {{ printModel.params.testMethodCustomerValue4 }}
              </td>
            </tr>
            <tr v-show="printModel.params.specificationCustomerKey5">
              <td style="width: 30%">
                <b>{{ printModel.params.specificationCustomerKey5 }}</b>
              </td>
              <td style="width: 35%">
                {{ printModel.params.specificationCustomerValue5 }}
              </td>
              <td
                style="width: 35%"
                v-show="
                  typeof printModel.params.testMethodCustomerValue5 !=
                  'undefined'
                "
              >
                {{ printModel.params.testMethodCustomerValue5 }}
              </td>
            </tr>

            <tr>
              <td style="width: 30%"><b>Color</b></td>
              <td style="width: 35%">
                {{ printModel.params.specificationColor }}
              </td>
              <td style="width: 35%">Visual</td>
            </tr>

            <tr>
              <td style="width: 30%"><b>Odor</b></td>
              <td style="width: 35%">
                {{ printModel.params.specificationOrdor }}
              </td>
              <td style="width: 35%">
                {{ printModel.params.testMethodOrdor }}
              </td>
            </tr>

            <tr>
              <td style="width: 30%"><b>Taste</b></td>
              <td style="width: 35%">
                {{ printModel.params.specificationTaste }}
              </td>
              <td style="width: 35%">
                {{ printModel.params.testMethodTaste }}
              </td>
            </tr>
          </tbody>
        </table>

        <!-- Microbiological Tests -->
        <!-- <div style="height: 10px"></div> -->
        <table style="width: 99%">
          <tbody>
            <tr>
              <th style="width: 30%">
                <b><u>Microbiological Tests</u></b>
              </th>
              <th style="width: 35%"><u>Specification</u></th>
              <th style="width: 35%"><u>Test Method</u></th>
            </tr>
            <tr v-show="printModel.params.specificationTotalPlateCount">
              <td style="width: 30%"><b>Total Plate Count</b></td>
              <td style="width: 35%">
                <span t-field="o.total_plate" />&lt;{{
                  printModel.params.specificationTotalPlateCount
                }}
                cfu/g
              </td>
              <td style="width: 35%">
                <span t-field="o.total_plate_test" />{{
                  printModel.params.testMethodTotalPlateCount
                }}
              </td>
            </tr>

            <tr v-show="printModel.params.specificationYeastAndMold">
              <td style="width: 30%"><b>Yeast and Mold</b></td>
              <td style="width: 35%">
                <span t-field="o.yeast_mold" />&lt;{{
                  printModel.params.specificationYeastAndMold
                }}
                cfu/g
              </td>
              <td style="width: 35%">
                <span t-field="o.yeast_mold_test" />{{
                  printModel.params.testMethodYeastAndMold
                }}
              </td>
            </tr>

            <tr v-show="printModel.params.specificationEcoli">
              <td style="width: 30%"><b>E. Coli</b></td>
              <td style="width: 35%">
                <span t-field="o.yeast_mold" />{{
                  printModel.params.specificationEcoli
                }}
              </td>
              <td style="width: 35%">
                <span t-field="o.yeast_mold_test" />{{
                  printModel.params.testMethodEcoli
                }}
              </td>
            </tr>

            <tr v-show="printModel.params.specificationSalmonella">
              <td style="width: 30%"><b>Salmonella</b></td>
              <td style="width: 35%">
                <span t-field="o.yeast_mold" />{{
                  printModel.params.specificationSalmonella
                }}
              </td>
              <td style="width: 35%">
                <span t-field="o.yeast_mold_test" />{{
                  printModel.params.testMethodSalmonella
                }}
              </td>
            </tr>

            <tr v-show="printModel.params.specificationStaphAureus">
              <td style="width: 30%"><b>Staph Aureus</b></td>
              <td style="width: 35%">
                <span t-field="o.yeast_mold" />{{
                  printModel.params.specificationStaphAureus
                }}
              </td>
              <td style="width: 35%">
                <span t-field="o.yeast_mold_test" />{{
                  printModel.params.testMethodTaphAureus
                }}
              </td>
            </tr>

            <tr v-show="printModel.params.specificationTotalColiforms">
              <td style="width: 30%"><b>Total Coliforms</b></td>
              <!-- <td style="width: 35%">
                <span t-field="o.yeast_mold" />&lt;{{
                  printModel.params.specificationTotalColiforms
                }}
                MPN/g
              </td> -->
              <td style="width: 35%" v-if="!printModel.params.specificationTotalColiforms || printModel.params.specificationTotalColiforms.toLowerCase()=='negative'">
                {{ printModel.params.specificationTotalColiforms }}
              </td>
              <td style="width: 35%" v-else>
                &lt;{{ printModel.params.specificationTotalColiforms }} MPN/g
              </td>
              <td style="width: 35%">
                <span t-field="o.yeast_mold_test" />{{
                  printModel.params.testMethodTotalColiforms
                }}
              </td>
            </tr>
          </tbody>
          
          <tbody>
            <tr v-show="printModel.params.microbiologicalCustomerKey1">
              <td style="width: 30%">
                <b>{{ printModel.params.microbiologicalCustomerKey1 }}</b>
              </td>
              <td style="width: 35%">
                {{
                  printModel.params.microbiologicalCustomerValue1
                }}
              </td>
              <td style="width: 35%">
                {{
                  printModel.params.microbiologicalTestMethodCustomerValue1
                }}
              </td>
            </tr>
          </tbody>
          <tbody v-for="(item, micIndex) in printModel.micTestsCustomFieldAttr" :key="micIndex">
            <tr v-show="item.customKey">
              <td style="width: 30%">
                <b>{{ item.customKey }}</b>
              </td>
              <td style="width: 35%">
                {{
                  item.customValue
                }}
              </td>
              <td style="width: 35%">
                {{
                  item.customValue1
                }}
              </td>
            </tr>
          </tbody>
        </table>
        <!-- <div style="height: 10px"></div> -->
        <table style="width: 99%">
          <tbody>
            <tr>
              <td style="width: 30%; font-size: 18px">
                <b><u>Other Notes</u></b>
              </td>
              <td style="width: 70%" />
            </tr>
            <tr v-if="printModel.params.shelflife">
              <td style="width: 30%"><b>Shelf Life</b></td>
              <td style="width70%">
                <span t-field="o.shelf_life" />{{ printModel.params.shelflife }}
              </td>
            </tr>

            <tr v-if="printModel.params.storage">
              <td style="width: 30%"><b>Storage</b></td>
              <td style="width70%">
                <span t-field="o.storage" />{{ printModel.params.storage }}
              </td>
            </tr>
          </tbody>
        </table>
        <div
          class="footer"
          id="footer"
          style="
            font-size: 11px;
            font-family: Times New Roman;
            margin-top: 10px;
          "
        >
          <div>
            <span>
              <i>
                * Color variation from batch to batch of the product may occur due to natural variations of raw material.
              </i>
            </span>
          </div>
          <!-- <div style="height: 10px"></div> -->
          <div>
            <span>
              <i>
                * The pH of powder product should be tested in its 1% aqueous solution if necessary.
              </i>
            </span>
          </div>
          <p v-for="(item, index) in printModel.newNoteArr" :key="index">
            * {{item.content}}
          </p>
          <!-- <div style="height: 10px"></div> -->
          <div>
            <b><u>Disclaimer: The product specifications, limits, test methods, and information contained on this document are accurate to the best of our knowledge based upon general industry information, available scientific data, and customer requests. These values are not intended to be used as specifications for a finished product and should be used only at the sole discretion of the purchaser. According to GMP regulations, the test results of the product must be independently verified by the purchasing party. The appropriate use of this product is the sole responsibility of the user or the purchasing party.</u></b>
          </div>
        </div>
        <FooterSigner></FooterSigner>
      </div>
    </div>
  </div>
</template>
<script>
import FooterSigner from "./FooterSigner";
export default {
  name: "CSFMU",
  components: { FooterSigner },
  props: ["printModel"],
  data() {
    return {
      username: "",
    };
  },
  computed: {
    maxArr() {
      let tmp = [
        this.printModel.ingredientsArr.length,
        this.printModel.inclNameArr.length,
        this.printModel.botanicalNameArr.length,
        this.printModel.commonOtherNameArr.length,
        this.printModel.partUsedArr.length,
      ].sort();
      return tmp[tmp.length - 1];
    },
    isShowTable(){
      if(this.printModel.params.tableInput1 || this.printModel.params.tableInput2 || this.printModel.params.tableInput3 || this.printModel.params.tableInput4 || this.printModel.params.tableInput5){
        return true
      }
      return false
    }
  },
  methods: {
    isSingle() {
      var count = 0;
      var name = "";
      var arr = [];
      if (this.printModel.commonOtherNameArr.length >= 1) {
        name = "Common/Other Name";
        count = count + 1;
        arr = this.printModel.commonOtherNameArr;
      }
      if (this.printModel.botanicalNameArr.length >= 1) {
        name = "Botanical Name";
        count = count + 1;
        arr = this.printModel.botanicalNameArr;
      }
      if (this.printModel.partUsedArr.length >= 1) {
        name = "Part Used";
        count = count + 1;
        arr = this.printModel.partUsedArr;
      }
      let res = { count, name, arr };
      return res;
    },
  },
};
</script>

<style scoped>
.tab tr td,
.tab thead th {
  padding: 5px;
}
</style>