<template>
  <div class="page" style="text-align: left">
    <!-- <div class="watermark" style="color: coral;height: 100%;width: 100%;position: absolute; margin: 0;text-align: center;line-height:100%;">ggsdfsdfsd</div> -->
    <div class="row">
      <div style="width: 99%">
        <div class="header" id="header" style="width: 100%; height: 100px">
          <div style="width: 11%; float: left">
            <img
              src="../../assets/print_logo.jpg"
              style="width: 100px; height: 100px"
            />
          </div>
          <div style="width: 89%; float: right">
            <div
              style="
                font-size: 28px;
                font-family: Times New Roman;
                text-align: center;
                vertical-align: text-top;
              "
            >
              <b>Draco Natural Products, Inc. </b>
            </div>
            <div
              style="
                font-size: 28px;
                font-family: Times New Roman;
                text-align: center;
                vertical-align: text-top;
              "
            >
              <b><u>PRODUCT SPECIFICATION</u></b>
            </div>
          </div>
        </div>

        <!-- Product info -->
        <table style="width: 99%">
          <tr v-show="printModel.params.productName.length > 0">
            <td style="width: 25%"><b>Product Name</b></td>
            <td style="width: 75%">
              <span t-field="o.name" />{{ printModel.params.productName }}
            </td>
          </tr>
          <tr v-show="printModel.params.versionNumber">
            <td style="width: 25%"><b>Version Number</b></td>
            <td style="width: 75%">
              <span t-field="o.name" />{{ printModel.params.versionNumber }}
            </td>
          </tr>

          <tr v-show="printModel.params.itemCode.length > 0">
            <td style="width: 25%"><b>Item Code</b></td>
            <td style="width: 75%">
              <span t-field="o.default_code" />{{ printModel.params.itemCode }}
            </td>
          </tr>
          <tr v-show="printModel.processingArr.length > 0">
            <td style="width: 25%"><b>Processing</b></td>
            <td style="width: 75%">
              <span
                t-field="o.description"
                :key="i"
                v-for="(tag, i) in printModel.processingArr"
                >{{ tag }}
                <template v-if="i + 1 < printModel.processingArr.length"
                  >,</template
                >
              </span>
            </td>
          </tr>
        </table>
        <table style="width: 99%" v-show="printModel.BaseInfoCustomFieldAttr && printModel.BaseInfoCustomFieldAttr.length>0">
          <tr v-for="(baseinfoCustom,baseinfoIndex) in printModel.BaseInfoCustomFieldAttr" :key="baseinfoIndex">
            <td style="width: 25%"><b>{{baseinfoCustom.baseInfoInput1}}</b></td>
            <td style="width: 75%">
              {{ baseinfoCustom.baseInfoInput2 }}
            </td>
          </tr>
        </table>

        <!-- <div style="height: 10px"></div> -->
        <div style="font-size: 18px">
          <b><u>General Specification</u></b>
        </div>
        <!-- 遍历table -->
        <template
          v-if="
            printModel.botanicalNameArr.length > 0 ||
            printModel.commonNameArr.length > 0 ||
            printModel.partUsedArr.length > 0
          "
        >
          <table
            style="width: 98%;border-collapse: collapse;"
            cellspacing="0"
            class="tab"
          >
            <thead>
              <tr style="text-align: left;border-bottom:2px solid gray">
                <!-- <th v-show="typeof(printModel.inclNameArr) == undefined ? true : printModel.inclNameArr.length>0">
                                                INCI Name
                                            </th> -->
                <th v-show="printModel.commonNameArr.length > 0">Common Name</th>
                <th v-show="printModel.botanicalNameArr.length > 0">Botanical Name</th>
                <th v-show="printModel.partUsedArr.length > 0">Part Used</th>
              </tr>
            </thead>
            <tbody>
              <tr :key="i" v-for="(tag, i) in maxArr">
                <!-- <td v-show="(i+1)<=printModel.inclNameArr.length">{{printModel.inclNameArr[i]}}</td> -->
                <td v-show="i + 1 <= printModel.commonNameArr.length">
                  {{ printModel.commonNameArr[i] }}
                </td>
                <td v-show="i + 1 <= printModel.botanicalNameArr.length" style="font-style:italic;">
                  {{ printModel.botanicalNameArr[i] }}
                </td>
                <td v-show="i + 1 <= printModel.partUsedArr.length">
                  {{ printModel.partUsedArr[i] }}
                </td>
              </tr>
            </tbody>
          </table>
        </template>
        
        <template v-if="isShowTable">
          <table
            style="width: 98%;margin-top:10px;"
            cellspacing="0"
            class="tab"
          >
            <tbody>
              <tr>
                <td v-show="printModel.params.tableInput1" style="width:20%;">{{ printModel.params.tableInput1 }}</td>
                <td v-show="printModel.params.tableInput2" style="width:20%;">{{ printModel.params.tableInput2 }}</td>
                <td v-show="printModel.params.tableInput3" style="width:20%;">{{ printModel.params.tableInput3 }}</td>
                <td v-show="printModel.params.tableInput4" style="width:20%;">{{ printModel.params.tableInput4 }}</td>
                <td v-show="printModel.params.tableInput5" style="width:20%;">{{ printModel.params.tableInput5 }}</td>
              </tr>
              <tr v-for="(item, tableInputIndex) in printModel.tableInputCustomFieldAttr" :key="tableInputIndex + 1">
                <td v-show="item.tableInput1">{{ item.tableInput1 }}</td>
                <td v-show="item.tableInput2">{{ item.tableInput2 }}</td>
                <td v-show="item.tableInput3">{{ item.tableInput3 }}</td>
                <td v-show="item.tableInput4">{{ item.tableInput4 }}</td>
                <td v-show="item.tableInput5">{{ item.tableInput5 }}</td>
              </tr>
            </tbody>
          </table>
        </template>

        <table style="width: 99%">
          <tbody>
            <tr
              v-show="
                typeof printModel.inclNameArr == 'undefined'
                  ? true
                  : printModel.inclNameArr.length > 0
              "
            >
              <td style="width: 30%"><b>INCI Name</b></td>
              <td style="width: 70%">
                <span
                  t-field="o.inci_name"
                  :key="i"
                  v-for="(tag, i) in printModel.inclNameArr"
                  >{{ tag }}
                  <template v-if="i + 1 < printModel.inclNameArr.length"
                    >,</template
                  >
                </span>
              </td>
            </tr>

            <!-- <tr v-show="typeof(printModel.commonOtherNameArr) == undefined ? true : printModel.commonOtherNameArr.length>0">
                                        <td style="width:30%"><b>Common Name</b></td>
                                        <td style="width:70%"><span t-field="o.apperance" :key="i" v-for="(tag,i) in printModel.commonOtherNameArr">{{tag}}
                                            <template v-if="i+1<printModel.commonOtherNameArr.length">,</template>
                                        </span></td>
                                    </tr>

                                    <tr v-show="printModel.botanicalNameArr.length>0">
                                        <td style="width:30%"><b>Botanical Name</b></td>
                                        <td style="width:70%"><span t-field="o.apperance" :key="i" v-for="(tag,i) in printModel.botanicalNameArr">{{tag}}
                                         <template v-if="i+1<printModel.botanicalNameArr.length">,</template>
                                        </span></td>
                                    </tr>

                                    <tr v-show="printModel.partUsedArr.length>0">
                                        <td style="width:30%"><b>Part Used</b></td>
                                        <td style="width:70%"><span t-field="o.apperance" :key="i" v-for="(tag,i) in printModel.partUsedArr">{{tag}}
                                             <template v-if="i+1<printModel.partUsedArr.length">,</template>
                                        </span></td>
                                    </tr> -->

            <tr v-show="printModel.params.appearance">
              <td style="width: 30%"><b>Appearance</b></td>
              <td style="width: 70%">
                <span t-field="o.apperance">{{
                  printModel.params.appearance
                }}</span>
              </td>
            </tr>

            <tr v-show="printModel.params.color.length > 0">
              <td style="width: 30%"><b>Color</b></td>
              <td style="width: 70%">
                <span t-field="o.apperance">{{ printModel.params.color }}</span>
              </td>
            </tr>

            <tr v-show="printModel.params.odor.length > 0">
              <td style="width: 30%"><b>Odor</b></td>
              <td style="width: 70%">
                <span t-field="o.apperance">{{ printModel.params.odor }}</span>
              </td>
            </tr>

            <tr v-show="printModel.params.taste.length > 0">
              <td style="width: 30%"><b>Taste</b></td>
              <td style="width: 70%">
                <span t-field="o.apperance">{{ printModel.params.taste }}</span>
              </td>
            </tr>

            <tr v-show="printModel.params.customValue1">
              <td style="width: 30%">
                <b>{{ printModel.params.customKey1 }}</b>
              </td>
              <td style="width: 70%">
                <span t-field="o.apperance">{{
                  printModel.params.customValue1
                }}</span>
              </td>
            </tr>

            <tr v-show="printModel.params.customValue2">
              <td style="width: 30%">
                <b>{{ printModel.params.customKey2 }}</b>
              </td>
              <td style="width: 70%">
                <span t-field="o.apperance">{{
                  printModel.params.customValue2
                }}</span>
              </td>
            </tr>

            <tr v-show="printModel.params.customValue3">
              <td style="width: 30%">
                <b>{{ printModel.params.customKey3 }}</b>
              </td>
              <td style="width: 70%">
                <span t-field="o.apperance">{{
                  printModel.params.customValue3
                }}</span>
              </td>
            </tr>
          </tbody>
          <tbody v-for="(item, genIndex) in printModel.genSpecCustomFieldAttr" :key="genIndex">
            <tr v-show="item.customKey">
              <td style="width: 30%">
                <b>{{ item.customKey }}</b>
              </td>
              <td style="width: 70%">
                <span t-field="o.apperance">{{
                  item.customValue
                }}</span>
              </td>
            </tr>
          </tbody>
        </table>

        <!-- Microbiological Tests -->
        <!-- <div style="height: 10px"></div> -->
        <table style="width: 99%">
          <thead>
            <tr>
              <th style="width: 30%">
                <b><u>Tests</u></b>
              </th>
              <th style="width: 35%"><u>Limit</u></th>
              <th style="width: 35%"><u>Method</u></th>
            </tr>
          </thead>
          <tbody v-for="(item, index) in printModel.shTestArr" :key="index">
            <tr v-if="item.testLimitAcidValue">
              <td style="width: 30%"><b>Acid Value</b></td>
              <td style="width: 35%">
                <span t-field="o.yeast_mold" />NMT{{
                  item.testLimitAcidValue
                }}mg KOH/G
              </td>
              <td style="width: 35%">
                <span t-field="o.yeast_mold_test" />{{
                  item.testMethodAcidValue
                }}
              </td>
            </tr>

            <tr v-if="item.testLimitPeroxideValue">
              <td style="width: 30%"><b>Peroxide Value</b></td>
              <td style="width: 35%">
                <span t-field="o.yeast_mold" />NMT{{
                  item.testLimitPeroxideValue
                }}meq/kg
              </td>
              <td style="width: 35%">
                <span t-field="o.yeast_mold_test" />{{
                  item.testMethodPeroxideValue
                }}
              </td>
            </tr>

            <tr v-if="item.testLimitSaponificationValue">
              <td style="width: 30%"><b>Saponification Value</b></td>
              <td style="width: 35%">
                <span t-field="o.yeast_mold" />{{
                  item.testLimitSaponificationValue
                }}mg KOH/g
              </td>
              <td style="width: 35%">
                <span t-field="o.yeast_mold_test" />{{
                  item.testMethodSaponificationValue
                }}
              </td>
            </tr>

            <tr v-if="item.testLimitUnsaponifiableMaster">
              <td style="width: 30%"><b>Unsaponifiable Matter</b></td>
              <td style="width: 35%">
                <span t-field="o.yeast_mold" />{{
                  item.testLimitUnsaponifiableMaster
                }}
                <template v-if="item.testLimitUnsaponifiableMaster"
                  >%</template
                >
              </td>
              <td style="width: 35%">
                <span t-field="o.yeast_mold_test" />{{
                  item.testMethodUnsaponifiableMaster
                }}
              </td>
            </tr>

            <tr v-if="item.testLimitMoistureVolatiles">
              <td style="width: 30%"><b>Moisture & Volatiles</b></td>
              <td style="width: 35%">
                <span t-field="o.yeast_mold" />NMT {{
                  item.testLimitMoistureVolatiles
                }}
                <template v-if="item.testLimitMoistureVolatiles"
                  >%</template
                >
              </td>
              <td style="width: 35%">
                <span t-field="o.yeast_mold_test" />{{
                  item.testMethodMoistureVolatiles
                }}
              </td>
            </tr>

            <tr v-if="item.testLimitHeavyMetals">
              <td style="width: 30%"><b>Total Heavy Metals</b></td>
              <td style="width: 35%">
                <span t-field="o.yeast_mold" /> &lt;
                {{ item.testLimitHeavyMetals }}ppm
              </td>
              <td style="width: 35%">
                <span t-field="o.yeast_mold_test" />USP &lt;231&gt;
              </td>
            </tr>

            <tr v-if="item.testLimitArsenic">
              <td style="width: 30%"><b>Arsenic</b></td>
              <td style="width: 35%">
                <span t-field="o.yeast_mold" />{{ item.testLimitArsenic }}ppm
              </td>
              <!-- <td style="width:35%"><span t-field="o.yeast_mold_test"/>AOAC 986.15</td> -->
              <td style="width: 35%">
                <span t-field="o.yeast_mold_test" />{{ item.testMethodArsenic }}
              </td>
            </tr>

            <tr v-if="item.testLimitCadmium">
              <td style="width: 30%"><b>Cadmium</b></td>
              <td style="width: 35%">
                <span t-field="o.yeast_mold" />{{ item.testLimitCadmium }}ppm
              </td>
              <!-- <td style="width:35%"><span t-field="o.yeast_mold_test"/>AOAC 986.15</td> -->
              <td style="width: 35%">
                <span t-field="o.yeast_mold_test" />{{ item.testMethodCadmium }}
              </td>
            </tr>

            <tr v-if="item.testLimitLead">
              <td style="width: 30%"><b>Lead</b></td>
              <td style="width: 35%">
                <span t-field="o.yeast_mold" />{{ item.testLimitLead }}ppm
              </td>
              <!-- <td style="width:35%"><span t-field="o.yeast_mold_test"/>AOCS Ca 18c-91</td> -->
              <td style="width: 35%">
                <span t-field="o.yeast_mold_test" />{{ item.testMethodLead }}
              </td>
            </tr>

            <tr v-if="item.testLimitMercury">
              <td style="width: 30%"><b>Mercury</b></td>
              <td style="width: 35%">
                <span t-field="o.yeast_mold" />{{ item.testLimitMercury }}ppm
              </td>
              <!-- <td style="width:35%"><span t-field="o.yeast_mold_test"/>AOAC 971.21</td> -->
              <td style="width: 35%">
                <span t-field="o.yeast_mold_test" />{{ item.testMethodMercury }}
              </td>
            </tr>

            <tr v-if="item.testLimitPesticides">
              <td style="width: 30%"><b>Pesticides</b></td>
              <td style="width: 35%">
                <span t-field="o.yeast_mold" />{{ item.testLimitPesticides }}
              </td>
              <td style="width: 35%">
                <span t-field="o.yeast_mold_test" />{{
                  item.testMethodPesticides
                }}
              </td>
            </tr>

            <tr v-show="item.testLimitCustomKey1">
              <td style="width: 30%">
                <b>{{ item.testLimitCustomKey1 }}</b>
              </td>
              <td style="width: 35%">
                <span t-field="o.yeast_mold" />{{
                  item.testLimitCustomKey1Value
                }}
              </td>
              <td style="width: 35%">
                <span t-field="o.yeast_mold_test" />{{
                  item.testMethodCustomKey1Value
                }}
              </td>
            </tr>
            <tr v-show="item.testLimitCustomKey2">
              <td style="width: 30%">
                <b>{{ item.testLimitCustomKey2 }}</b>
              </td>
              <td style="width: 35%">
                <span t-field="o.yeast_mold" />{{
                  item.testLimitCustomKey2Value
                }}
              </td>
              <td style="width: 35%">
                <span t-field="o.yeast_mold_test" />{{
                  item.testMethodCustomKey2Value
                }}
              </td>
            </tr>
            <tr v-for="(test, testIndex) in item.testCustomFieldAttr" :key="testIndex">
              <td v-show="test.customKey" style="width: 30%">
                <b>{{ test.customKey }}</b>
              </td>
              <td v-show="test.customKey" style="width: 35%">
                {{
                  test.customValue
                }}
              </td>
              <td v-show="test.customKey" style="width: 35%">
                {{
                  test.customValue1
                }}
              </td>
            </tr>
          </tbody>
          <!-- Microbiological Tests -->
          <tbody> 
            <tr v-show="printModel.params.specificationTotalPlateCount">
              <td style="width: 30%"><b>Total Plate Count</b></td>
              <td style="width: 35%">
                <span t-field="o.total_plate" />&lt;{{
                  printModel.params.specificationTotalPlateCount
                }}
                cfu/g
              </td>
              <td style="width: 35%">
                <span t-field="o.total_plate_test" />
                {{ printModel.params.testMethodTotalPlateCount }}
              </td>
            </tr>

            <tr v-show="printModel.params.specificationYeastAndMold">
              <td style="width: 30%"><b>Yeast and Mold</b></td>
              <td style="width: 35%">
                <span t-field="o.yeast_mold" />&lt;{{
                  printModel.params.specificationYeastAndMold
                }}
                cfu/g
              </td>
              <td style="width: 35%">
                <span t-field="o.yeast_mold_test" />{{
                  printModel.params.testMethodYeastAndMold
                }}
              </td>
            </tr>

            <tr v-show="printModel.params.specificationEcoli">
              <td style="width: 30%"><b>E. Coli</b></td>
              <td style="width: 35%">
                <span t-field="o.yeast_mold" />{{
                  printModel.params.specificationEcoli
                }}
              </td>
              <td style="width: 35%">
                <span t-field="o.yeast_mold_test" />{{
                  printModel.params.testMethodEcoli
                }}
              </td>
            </tr>

            <tr v-show="printModel.params.specificationSalmonella">
              <td style="width: 30%"><b>Salmonella</b></td>
              <td style="width: 35%">
                <span t-field="o.yeast_mold" />{{
                  printModel.params.specificationSalmonella
                }}
              </td>
              <td style="width: 35%">
                <span t-field="o.yeast_mold_test" />{{
                  printModel.params.testMethodSalmonella
                }}
              </td>
            </tr>

            <tr v-show="printModel.params.specificationStaphAureus">
              <td style="width: 30%"><b>Staph Aureus</b></td>
              <td style="width: 35%">
                <span t-field="o.yeast_mold" />{{
                  printModel.params.specificationStaphAureus
                }}
              </td>
              <td style="width: 35%">
                <span t-field="o.yeast_mold_test" />{{
                  printModel.params.testMethodTaphAureus
                }}
              </td>
            </tr>

            <tr v-show="printModel.params.specificationTotalColiforms">
              <td style="width: 30%"><b>Total Coliforms</b></td>
              <!-- <td style="width: 35%">
                <span t-field="o.yeast_mold" />&lt;{{
                  printModel.params.specificationTotalColiforms
                }}
                MPN/g
              </td> -->
              <td style="width: 35%" v-if="!printModel.params.specificationTotalColiforms || printModel.params.specificationTotalColiforms.toLowerCase()=='negative'">
                {{ printModel.params.specificationTotalColiforms }}
              </td>
              <td style="width: 35%" v-else>
                &lt;{{ printModel.params.specificationTotalColiforms }} MPN/g
              </td>
              <td style="width: 35%">
                <span t-field="o.yeast_mold_test" />{{
                  printModel.params.testMethodTotalColiforms
                }}
              </td>
            </tr>

            <tr v-if="printModel.params.microbiologicalCustomerKey1">
              <td style="width: 30%">
                <b>{{ printModel.params.microbiologicalCustomerKey1 }}</b>
              </td>
              <td style="width: 35%">
                {{ printModel.params.microbiologicalCustomerValue1 }}
              </td>
              <td style="width: 35%">
                {{ printModel.params.microbiologicalTestMethodCustomerValue1 }}
              </td>
            </tr>
          </tbody>
          <tbody v-for="(item, micIndex) in printModel.micTestsCustomFieldAttr" :key="micIndex">
            <tr v-show="item.customKey">
              <td style="width: 30%">
                <b>{{ item.customKey }}</b>
              </td>
              <td style="width: 35%">
                {{
                  item.customValue
                }}
              </td>
              <td style="width: 35%">
                {{
                  item.customValue1
                }}
              </td>
            </tr>
          </tbody>
        </table>
        <!-- Analysis -->
        <!-- <div style="height: 10px"></div> -->
        <table style="width: 99%">
          <tbody
            v-for="(item, index) in printModel.fattyAcidCompositionArr"
            :key="index"
          >
            <tr
        v-if="item.fattryLimitCaprylic 
         || item.fattryLimitCapric 
         || item.fattryLimitLauric
         || item.fattryLimitMyristic
         || item.fattryLimitMyristoleic
         || item.fattryLimitPalmitica
         || item.fattryLimitPalmitoleic
         || item.fattryLimitStearic 
         || item.fattryLimitOleicVaccenic 
         || item.fattryLimitLinoleic 
         || item.fattryLimitALinolenic
         || item.fattryLimitLinoleicacid
         || item.fattryLimitPunicicacid
         || item.fattryLimitArachidic
         || item.fattryLimitArachidonic
         || item.fattryLimitEicosapentaenoic
         || item.fattryLimitDocosahexaenoic
         || item.fattryLimitKey1
         || item.fattryLimitKey2
         || item.fattryLimitKey3
         || item.fattyCustomFieldAttr.length>0
         || item.fatterLimitOthers">
              <td style="width: 30%">
                <u><b>Fatty Acid Composition</b></u>
              </td>
              <td style="width: 35%">
                <u><b>Limit</b></u>
              </td>
              <td style="width: 35%">
                <u><b>Test Method</b></u>
              </td>
            </tr>
            <!-- <tr v-show="item.fattryLimitKey1">
              <td style="width: 30%">
                <b>{{ item.fattryLimitKey1 }}</b>
              </td>
              <td style="width: 35%">{{ item.fattryLimitKey1Value }}</td>
              <td style="width: 35%">{{ item.fattryMethodKey1Value }}</td>
            </tr>
            <tr v-show="item.fattryLimitKey2">
              <td style="width: 30%">
                <b>{{ item.fattryLimitKey2 }}</b>
              </td>
              <td style="width: 35%">{{ item.fattryLimitKey2Value }}</td>
              <td style="width: 35%">{{ item.fattryMethodKey2Value }}</td>
            </tr> -->
            <tr v-if="item.fattryLimitCaprylic">
              <td style="width: 30%"><b>C8:0 Caprylic acid </b></td>
              <td style="width: 35%">{{ item.fattryLimitCaprylic }}%</td>
              <td style="width: 35%">{{ item.fattryMethodCaprylic }}</td>
            </tr>
            <tr v-if="item.fattryLimitCapric">
              <td style="width: 30%"><b>C10:0 Capric acid </b></td>
              <td style="width: 35%">{{ item.fattryLimitCapric }}%</td>
              <td style="width: 35%">{{ item.fattryMethodCapric }}</td>
            </tr>

            <tr v-if="item.fattryLimitLauric">
              <td style="width: 30%"><b>C12:0 Lauric acid </b></td>
              <td style="width: 35%">{{ item.fattryLimitLauric }}%</td>
              <td style="width: 35%">{{ item.fattryMethodLauric }}</td>
            </tr>

            <tr v-if="item.fattryLimitMyristic">
              <td style="width: 30%"><b>C14:0 Myristic acid </b></td>
              <td style="width: 35%">{{ item.fattryLimitMyristic }}%</td>
              <td style="width: 35%">{{ item.fattryMethodMyristic }}</td>
            </tr>

            <tr v-if="item.fattryLimitMyristoleic">
              <td style="width: 30%"><b>C14:1 Myristoleic acid  </b></td>
              <td style="width: 35%">{{ item.fattryLimitMyristoleic }}%</td>
              <td style="width: 35%">{{ item.fattryMethodMyristoleic }}</td>
            </tr>

            <tr v-if="item.fattryLimitPalmitica">
              <td style="width: 30%"><b>C16:0 Palmitic acid </b></td>
              <td style="width: 35%">{{ item.fattryLimitPalmitica }}%</td>
              <td style="width: 35%">{{ item.fattryMethodPalmitica }}</td>
            </tr>

            <tr v-if="item.fattryLimitPalmitoleic">
              <td style="width: 30%"><b>C16:1 Palmitoleic acid</b></td>
              <td style="width: 35%">{{ item.fattryLimitPalmitoleic }}%</td>
              <td style="width: 35%">{{ item.fattryMethodPalmitoleic }}</td>
            </tr>

            <tr v-if="item.fattryLimitStearic">
              <td style="width: 30%"><b>C18:0 Stearic acid</b></td>
              <td style="width: 35%">
                {{ item.fattryLimitStearic }}
                <template v-if="item.fattryLimitStearic">%</template>
              </td>
              <td style="width: 35%">{{ item.fattryMethodStearic }}</td>
            </tr>

            <tr v-if="item.fattryLimitOleicVaccenic">
              <td style="width: 30%"><b>C18:1 Oleic acid</b></td>
              <td style="width: 35%">
                <span t-field="o.standard2"
                  >{{ item.fattryLimitOleicVaccenic }}
                  <template v-if="item.fattryLimitOleicVaccenic"
                    >%</template
                  >
                </span>
              </td>
              <td style="width: 35%">{{ item.fattryMethodOleicVaccenic }}</td>
            </tr>

            <tr v-if="item.fattryLimitLinoleic">
              <td style="width: 30%"><b>C18:2 Linoleic acid</b></td>
              <td style="width: 35%">
                {{ item.fattryLimitLinoleic }}
                <template v-if="item.fattryLimitLinoleic"
                  >%</template
                >
              </td>
              <td style="width: 35%">{{ item.fattryMethodLinoleic }}</td>
            </tr>

            <tr v-if="item.fattryLimitALinolenic">
              <td style="width: 30%"><b>C18:3 α-Linolenic acid</b></td>
              <td style="width: 35%">
                {{ item.fattryLimitALinolenic }}
                <template v-if="item.fattryLimitALinolenic"
                  >%</template
                >
              </td>
              <td style="width: 35%">{{ item.fattryMethodALinolenic }}</td>
            </tr>

            <tr v-if="item.fattryLimitLinoleicacid">
              <td style="width: 30%"><b>C18:3 γ-Linoleic acid</b></td>
              <td style="width: 35%">
                {{ item.fattryLimitLinoleicacid }}
                <template v-if="item.fattryLimitLinoleicacid"
                  >%</template
                >
              </td>
              <td style="width: 35%">{{ item.fattryMethodLinoleicacid }}</td>
            </tr>

            <tr v-if="item.fattryLimitPunicicacid">
              <td style="width: 30%"><b>C18:3 Punicic acid</b></td>
              <td style="width: 35%">
                {{ item.fattryLimitPunicicacid }}
                <template v-if="item.fattryLimitPunicicacid">%</template>
              </td>
              <td style="width: 35%">{{ item.fattryMethodPunicicacid }}</td>
            </tr>

            <tr v-if="item.fattryLimitArachidic">
              <td style="width: 30%"><b>C 20:0 Arachidic acid </b></td>
              <td style="width: 35%">{{ item.fattryLimitArachidic }}%</td>
              <td style="width: 35%">{{ item.fattryMethodArachidic }}</td>
            </tr>

            <tr v-if="item.fattryLimitArachidonic">
              <td style="width: 30%"><b>C20:4 Arachidonic acid </b></td>
              <td style="width: 35%">{{ item.fattryLimitArachidonic }}%</td>
              <td style="width: 35%">{{ item.fattryMethodArachidonic }}</td>
            </tr>

            <tr v-if="item.fattryLimitEicosapentaenoic">
              <td style="width: 30%"><b>C20:5 Eicosapentaenoic acid</b></td>
              <td style="width: 35%">{{ item.fattryLimitEicosapentaenoic }}%</td>
              <td style="width: 35%">{{ item.fattryMethodEicosapentaenoic }}</td>
            </tr>

            <tr v-if="item.fattryLimitDocosahexaenoic">
              <td style="width: 30%"><b>C 22:6 Docosahexaenoic acid </b></td>
              <td style="width: 35%">{{ item.fattryLimitDocosahexaenoic }}%</td>
              <td style="width: 35%">{{ item.fattryMethodDocosahexaenoic }}</td>
            </tr>

            <tr v-show="item.fattryLimitKey3">
              <td style="width: 30%">
                <b>{{ item.fattryLimitKey3 }}</b>
              </td>
              <td style="width: 35%">{{ item.fattryLimitKey3Value }}</td>
              <td style="width: 35%">{{ item.fattryMethodKey3Value }}</td>
            </tr>
            <tr v-show="item.fattryLimitKey4Value">
              <td style="width: 30%">
                <b>{{ item.fattryLimitKey4 }}</b>
              </td>
              <td style="width: 35%">{{ item.fattryLimitKey4Value }}</td>
              <td style="width: 35%">{{ item.fattryMethodKey4Value }}</td>
            </tr>
            <tr v-show="item.fattryLimitKey5Value">
              <td style="width: 30%">
                <b>{{ item.fattryLimitKey5 }}</b>
              </td>
              <td style="width: 35%">{{ item.fattryLimitKey5Value }}</td>
              <td style="width: 35%">{{ item.fattryMethodKey5Value }}</td>
            </tr>
            
            <tr v-for="(fatty, fattyIndex) in item.fattyCustomFieldAttr" :key="fattyIndex">
              <td v-show="fatty.customKey" style="width: 30%">
                <b>{{ fatty.customKey }}</b>
              </td>
              <td v-show="fatty.customKey" style="width: 35%">
                {{
                  fatty.customValue
                }}
              </td>
              <td v-show="fatty.customKey" style="width: 35%">
                {{
                  fatty.customValue1
                }}
              </td>
            </tr>

            <tr v-if="item.fatterLimitOthers">
              <td style="width: 30%"><b>Others</b></td>
              <td style="width: 35%">
                {{ item.fatterLimitOthers }}
                <template>%</template>
              </td>
              <td style="width: 35%">{{ item.fatterMethodOthers }}</td>
            </tr>
          </tbody>
        </table>

        <!-- <div style="height: 10px"></div>
        <table style="width: 99%">
          <tbody>
            <tr>
              <th style="width: 30%">
                <b><u>Microbiological Tests</u></b>
              </th>
              <th style="width: 35%"><u>Specification</u></th>
              <th style="width: 35%"><u>Test Method</u></th>
            </tr>
            <tr v-show="printModel.params.specificationTotalPlateCount">
              <td style="width: 30%"><b>Total Plate Count</b></td>
              <td style="width: 35%">
                <span t-field="o.total_plate" />&lt;{{
                  printModel.params.specificationTotalPlateCount
                }}
                cfu/g
              </td>
              <td style="width: 35%">
                <span t-field="o.total_plate_test" />
                {{ printModel.params.testMethodTotalPlateCount }}
              </td>
            </tr>

            <tr v-show="printModel.params.specificationYeastAndMold">
              <td style="width: 30%"><b>Yeast and Mold</b></td>
              <td style="width: 35%">
                <span t-field="o.yeast_mold" />&lt;{{
                  printModel.params.specificationYeastAndMold
                }}
                cfu/g
              </td>
              <td style="width: 35%">
                <span t-field="o.yeast_mold_test" />{{
                  printModel.params.testMethodYeastAndMold
                }}
              </td>
            </tr>

            <tr v-show="printModel.params.specificationEcoli">
              <td style="width: 30%"><b>E. Coli</b></td>
              <td style="width: 35%">
                <span t-field="o.yeast_mold" />{{
                  printModel.params.specificationEcoli
                }}
              </td>
              <td style="width: 35%">
                <span t-field="o.yeast_mold_test" />{{
                  printModel.params.testMethodEcoli
                }}
              </td>
            </tr>

            <tr v-show="printModel.params.specificationSalmonella">
              <td style="width: 30%"><b>Salmonella</b></td>
              <td style="width: 35%">
                <span t-field="o.yeast_mold" />{{
                  printModel.params.specificationSalmonella
                }}
              </td>
              <td style="width: 35%">
                <span t-field="o.yeast_mold_test" />{{
                  printModel.params.testMethodSalmonella
                }}
              </td>
            </tr>

            <tr v-show="printModel.params.specificationStaphAureus">
              <td style="width: 30%"><b>Staph Aureus</b></td>
              <td style="width: 35%">
                <span t-field="o.yeast_mold" />{{
                  printModel.params.specificationStaphAureus
                }}
              </td>
              <td style="width: 35%">
                <span t-field="o.yeast_mold_test" />{{
                  printModel.params.testMethodTaphAureus
                }}
              </td>
            </tr>

            <tr v-show="printModel.params.specificationTotalColiforms">
              <td style="width: 30%"><b>Total Coliforms</b></td>
              <td style="width: 35%">
                <span t-field="o.yeast_mold" />&lt;{{
                  printModel.params.specificationTotalColiforms
                }}
                MPN/g
              </td>
              <td style="width: 35%">
                <span t-field="o.yeast_mold_test" />{{
                  printModel.params.testMethodTotalColiforms
                }}
              </td>
            </tr>

            <tr v-if="printModel.params.microbiologicalCustomerKey1">
              <td style="width: 30%">
                <b>{{ printModel.params.microbiologicalCustomerKey1 }}</b>
              </td>
              <td style="width: 35%">
                {{ printModel.params.microbiologicalCustomerValue1 }}
              </td>
              <td style="width: 35%">
                {{ printModel.params.microbiologicalTestMethodCustomerValue1 }}
              </td>
            </tr>
          </tbody>
        </table> -->
        <!-- <div style="height: 10px"></div> -->
        <table style="width: 99%">
          <tbody>
            <tr>
              <td style="width: 30%; font-size: 18px">
                <b><u>Other Notes</u></b>
              </td>
              <td style="width: 70%" />
            </tr>
            <tr v-if="printModel.params.shelflife">
              <td style="width: 30%"><b>Shelf Life</b></td>
              <td style="width70%">
                <span t-field="o.shelf_life" />{{ printModel.params.shelflife }}
              </td>
            </tr>

            <tr v-if="printModel.params.storage">
              <td style="width: 30%"><b>Storage</b></td>
              <td style="width70%">
                <span t-field="o.storage" />{{ printModel.params.storage }}
              </td>
            </tr>

            <tr v-show="printModel.params.packageInfo.length > 0">
              <td style="width: 30%"><b>Package</b></td>
              <td style="width70%">
                <span t-field="o.shelf_life" />{{
                  printModel.params.packageInfo
                }}
              </td>
            </tr>
          </tbody>
        </table>

        <div
          class="footer"
          id="footer"
          style="
            font-size: 11px;
            font-family: Times New Roman;
            margin-top: 10px;
          "
        >
          <div>
            <span>
                <i>
                  * Color variation from batch to batch of the product may occur due to natural variations of raw material.
                </i>
              </span>
          </div>
          <!-- <div style="height: 10px"></div> -->
          <div>
            <span>
                <i>
                  * Some natural sediment may occur upon standing, that does not compromise the quality of the product; please warm to ambient temperature and mix well before use in such case.
                </i>
              </span>
          </div>
          <p v-for="(item, index) in printModel.newNoteArr" :key="index">
            * {{item.content}}
          </p>
          <!-- <div style="height: 10px"></div> -->
          <div>
            <b><u>Disclaimer: The product specifications, limits, test methods, and information contained on this document are accurate to the best of our knowledge based upon general industry information, available scientific data, and customer requests. These values are not intended to be used as specifications for a finished product and should be used only at the sole discretion of the purchaser. According to GMP regulations, the test results of the product must be independently verified by the purchasing party. The appropriate use of this product is the sole responsibility of the user or the purchasing party.</u></b>
          </div>
        </div>
        <FooterSigner></FooterSigner>
      </div>
    </div> 
  </div>
</template>
<script>
import FooterSigner from "./FooterSigner";
export default {
  name: "CSFMU",
  components: { FooterSigner },
  props: ["printModel"],
  data() {
    return {
      username: "",
    };
  },
  computed: {
    maxArr() {
      let tmp = [
        this.printModel.ingredientsArr.length,
        this.printModel.inclNameArr.length,
        this.printModel.botanicalNameArr.length,
        this.printModel.commonOtherNameArr.length,
        this.printModel.partUsedArr.length,
      ].sort();
      return tmp[tmp.length - 1];
    },
    isShowTable(){
      if(this.printModel.params.tableInput1 || this.printModel.params.tableInput2 || this.printModel.params.tableInput3 || this.printModel.params.tableInput4 || this.printModel.params.tableInput5){
        return true
      }
      return false
    }
  },
  methods: {},
};
</script>

<style scoped>
.tab tr td,
.tab thead th {
  padding: 5px;
}
.watermark {
  height: 100%;
  width: 100%;
  position: absolute;
  margin: 0;
  text-align: center;
  border: solid 1px;
  color: coral;
  background-color: coral;
}
</style>